<template lang="html">
<div>
  <b-form class="c-f-g-2-wrp sm" @submit.prevent="onSubmit()">
    <h5 class="main-page-title">Mi firma</h5>
    <hr class="c-hr" />

    <b-form-group class="cus-f-group-2 mb-1 text-center" label="Esta rubrica ser tomada para referencia para la firma de todos los próximos documentos que realices."></b-form-group>

    <div class="row justify-content-center">
      <div class="col-lg-9 mb-4">
        <div class="box-image-signature">
          <img class="empty" src="public/images/shared/empty.png">

          <div class="box-btn-signature" @click="openSignatureModal" v-if="!$root.user.signatureUrl">
            <div class="inside">
              <h5>AGREGA NUEVA FIRMA</h5>
            </div>
          </div>

          <div class="box-fake-signature" v-else>
            <div class="fake-image" v-bind:style="{ backgroundImage: 'url('+$root.user.signatureUrl+')' }"></div>
            <!-- <div class="box-button">
              <button class="btn btn-s1 bg-black" @click="removeImage('imageSignature')">Eliminar firma y subir una nueva</button>
            </div> -->
          </div>
        </div>
      </div>

      <!-- <div class="col-12 text-center">
        <b-button type="submit" class="btn-s1 bg-black" v-if="$root.user.signatureUrl">Guardar firma</b-button>
      </div> -->
    </div>

  </b-form>

  <!-- Modal firma -->
  <b-modal modal-class="modal-signature" ref="modal-signature" centered hide-footer title="" @hidden="showSignaturePad = false">
    <div class="box-content">
      <p style="text-align: center;">Firma igual que en tu INE</p>
      <div class="box-signature" v-if="showSignaturePad">
        <VueSignaturePad class="signature-pad" ref="signaturePad" />

        <div class="box-buttons">
          <button class="btn btn-s1 bg-black" @click="saveSignature">Guardar</button>
          <button class="btn btn-s1 bg-black" @click="resetSignature">Limpiar</button>
        </div>
      </div>
    </div>
  </b-modal>
  <!--  -->

  <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
    <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
    {{modal.msg}}
    <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
    <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
    </div>
  </sweet-modal>
</div>
</template>

<script>
export default {
  data(){
    return{
      imageSignature: '',
      showSignaturePad: false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    resetSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // console.log(isEmpty);
      // console.log(data);
      this.$root.user.signatureUrl = data;
      this.$refs['modal-signature'].hide();
    },

    openSignatureModal(){
      this.$refs['modal-signature'].show();

      setTimeout(()=>{ this.showSignaturePad = true; }, 1000);
    },

    removeImage: function (target) {
      if(target == 'imagePhoto'){
        this.imagePhoto = false;
      }
      if(target == 'imageSignature'){
        this.$root.user.signatureUrl = false;
      }
    },

    onSubmit(){
      this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();

        var formData = new FormData();
        formData.append("signature", this.$root.user.signatureUrl);

        axios.post(tools.url('/api/user/signature'), formData).then((response)=>{
            if(response.data.status == 'success'){
                this.modal.block = false;
                this.modal.icon = "success";
                this.modal.msg = response.data.msg;
                this.$root.auth();

            }else{
                this.modal.block = false;
                this.modal.icon = "error";
                this.modal.msg = response.data.msg;
            }
           
        }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = response.data.msg;
            
            this.handleErrors(error);
        });
    }
  },
}
</script>
