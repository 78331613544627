<template lang="html">
  <div id="info-page-s1">
    <section class="placed-backg banner-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/banner-1.jpg)' }"></section>

    <section class="main-section m-h-inherit accreditations-section">
      <div class="container oversized-container">
        <h1 class="title-s-1">Acreditaciones</h1>

        <div class="row">
          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-1.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de emisión de certificados digitales</h6>
              <!-- <h5 class="date">DOF 20/06/2011</h5> -->
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-2.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de emisión de constancias de conservación de mensajes de datos NOM 151 SCFI 2016</h6>
              <!-- <h5 class="date">DOF 14/12/2011</h5> -->
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-1.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de emisión de sellos digitales de tiempo</h6>
              <!-- <h5 class="date">DOF 14/12/2011</h5> -->
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-2.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de digitalización de documentos en soporte físico como Tercero Legalmente Autorizado TLA</h6>
              <!-- <h5 class="date">DOF 03/12/2019</h5> -->
              <!-- <h5 class="date f-w-400 pt-1">ASESORÍA JURÍDICA GRATUÍTA</h5> -->
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
