<template lang="html">
  <b-form @submit="onSubmit" id="packages-page">

    <section class="container oversized-container packages-section">
      <h1 class="title-s-1">Selecciona un paquete de créditos</h1>

      <b-form-group v-slot="{ ariaDescribedby }" class="box-packages-options">
        <b-form-radio class="col-md-6 col-lg-4 box-credit-s2"
          required
          v-model="form.package_id"
          :aria-describedby="ariaDescribedby"
          name="radios-pqs"
          :value="p.id"
          v-for="(p, pInx) in packages"
          :key="'pInx-'+pInx">
          <div class="box">
            <!-- <div class="col-xl-5 align-self-center col-image">
              <img :src="p.imgURL">
            </div> -->

            <div class="col-xl-7 col-info-1">
              <h4 class="num" v-html="p.credits"></h4>
              <h5 class="name">{{p.name}}</h5>
              <!-- <span class="price">{{ p.price }}</span> -->
              <div class="descr">
                {{ p.description }}
              </div>
              <span class="price inverted">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} MXN</span>
            </div>

            <div class="col-12 align-items-center col-button">
              <a class="_btn">
                <span>Seleccionar</span><span class="selected">Seleccionado</span>
              </a>
            </div>
          </div>
        </b-form-radio>
      </b-form-group>
    </section>

    <section class="payment-section">
      <div class="container">
        <h2 class="text-center title-s-1">Información de pago <img class="img-payments" src="public/images/shared/payments.svg" /></h2>

        <div class="row justify-content-center">

          <b-form-group class="cus-f-group-1 col-md-12 col-lg-4" label="Método de pago">
            <b-form-select required v-model="form.payment_method">
              <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              <b-form-select-option value="deposito">Deposito</b-form-select-option>
              <b-form-select-option value="tarjeta">Tarjeta de crédito/débito</b-form-select-option>
            </b-form-select>
          </b-form-group>
          
          <div class="col-lg-12" v-show="form.payment_method == 'tarjeta'">
              <label class="mt-3">Detalles de tarjeta:</label>
              <div id="cardElement"></div>
              <small class="form text text-muted" id="cardErrors" role="alert"></small>
              <br>
          </div>
          <div class="col-12" v-show="form.payment_method == 'deposito'">
          <img src="public/images/banamex.png" width="100">
                <p><strong>BANCO:</strong> BANAMEX</p>
                <p><strong>TITULAR:</strong> 44 Y PUNTO S.A. DE C.V.</p>
                <p><strong>CLABE:</strong> 002692701842845439</p>
                
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pago@notarionet.com</strong>
                </p>
          </div> <br><br>

          


          <div class="col-12 text-center">
            <p>
              <b-button type="submit" class="btn btn-s1 bg-black" v-show="form.payment_method == 'deposito'">PROCESAR PEDIDO</b-button>
              <b-button type="submit" class="btn btn-s1 bg-black" v-show="form.payment_method == 'tarjeta'">PROCESAR PAGO</b-button>
            </p>
          </div>
        </div>
      </div>
       <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="toHome()">Entendido</b-button>
        </div>
      </sweet-modal>
    </section>

  </b-form>

 

</template>
<script>
export default {
  data(){
    return{
      currentYear: 0,

      packages: [
        // { id: 1, n: '1', name:'Contrato, acuerdo o documento', imgURL: 'public/images/pages/packages/paper-1.svg', price: '$500.00 MXN',total:500 },
        // { id: 2, n: '3', name:'Documentos', imgURL: 'public/images/pages/packages/paper-2.svg', price: '$1,000.00 MXN',total:1000 },
        // { id: 3, n: '5', name:'Documentos', imgURL: 'public/images/pages/packages/paper-3.svg', price: '$2,500.00 MXN',total:2500 },
      ],

      form: {

        quantity:null,
        total:null,
        package_id:null,
        payment_method:null,
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },
  watch:{
      'form.package_id':function(val){
          var indxp = null;
          for (var i = 0; i < this.packages.length; i++) {
              if(this.packages[i]['id'] == val){
                  indxp = i;
                  break;
              }
          }


          this.form.quantity = this.packages[indxp]['credits'];
          this.form.total = this.packages[indxp]['price'];
      }
  },
  methods: {

    /*onSubmit(event) {
      event.preventDefault();
      this.modal.icon = "";
      this.modal.msg = 'Cargando...';
      this.modal.block = true;
      this.$refs.modal.open();


        axios.post(tools.url('/api/credits'),this.form).then((response)=>{
          this.modal.block = false;
            this.modal.icon = "success";

            this.modal.msg = 'Gracias<br>Tu compra de '+this.form.quantity+' creditos ha sido generada<br><br>Recibiras 1 recibo en tu correo de esta compra';
            this.$root.auth();
        }).catch((error)=>{
              this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = 'Error al generar compra';
        });

    },*/
    toHome(){
      this.$refs.modal.close();
      this.$router.push('/');
    },
    getPackages(){
        axios.get(tools.url('/api/packages')).then((response)=>{
            this.packages = response.data;
        }).catch((error)=>{

        });
    },
    loadStripe(){
      //sandbox key
      //this.stripe = Stripe('pk_test_51MJg6MFbOx5yWSN82dR9gGNKxV58qesGnCnO2D2IZ6mCo351qXpC3gWd5PLW1HFFb9mOfx5NGVhgqGKrnZLKXECD00ib1BjA5x');
      this.stripe = Stripe('pk_live_51MJg6MFbOx5yWSN8Rv2FnMKEzBnSjQknJ6BiYpFiZdYZ2G8LNFZ7WOMctTwrkRCUm3MrUFkgqXvZ8DVJgpDIbF6f00TreTHrGI');

      this.elements = this.stripe.elements({locale: 'es'});
      this.cardElement = this.elements.create('card', {hidePostalCode: true});

      this.cardElement.mount("#cardElement");
      this.cardElement.addEventListener('change', ({ error }) => {
          const displayError = document.getElementById('cardErrors');
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = '';
          }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      

      if (this.form.payment_method == 'deposito') {
          this.saveOrder();
      }
      else if(this.form.payment_method == 'tarjeta'){

        this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Validando el metodo de pago. por favor espere...';
      this.$refs.modal.open();

          this.stripe.createPaymentMethod({
              type: 'card',
              card: this.cardElement,
              billing_details: { name: this.$root.user.name+' '+this.$root.user.lastname, email: this.$root.user.email }
            }).then((result)=>{
              if (result.error) {
                alert('Ocurrió un error con su tarjeta');
                this.desactivar = false;
              } else {
                const data  = { payment_method_id: result.paymentMethod.id, total: this.form.total, name: this.$root.user.name, email: this.$root.user.email };
                axios.post(tools.url('/api/stripe/Installments'),data).then((response)=>{
                    this.form.payment_intent_id = response.data.intent_id;
                    this.pagarStripe();
                  });
              }
          });
      }
    },

    pagarStripe: function(){
      this.modal.icon = "";
      this.modal.msg = 'Cargando...';
      this.modal.block = true;
      //this.$refs.modal.open();


        axios.post(tools.url('/api/credits'),this.form).then((response)=>{


          if (response.data.type == 'success') {
            this.modal.block = false;
              this.modal.icon = "success";

              this.modal.msg = 'Gracias<br>Tu compra de '+this.form.quantity+' creditos ha sido generada<br><br>Recibiras 1 recibo en tu correo de esta compra';
              this.$root.auth();

             
              var self = this;

          }
          else{
              this.modal.block = false;
              this.modal.icon = "error";
              this.modal.msg = response.data.message;
          }
        }).catch((error)=>{
          this.desactivar = false;
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = 'Error al generar compra';
        });

    },
    saveOrder: function(){
      this.modal.icon = "";
      this.modal.msg = 'Cargando...';
      this.modal.block = true;
      this.$refs.modal.open();


        axios.post(tools.url('/api/creditsdeposit'),this.form).then((response)=>{
          this.modal.block = false;
            this.modal.icon = "success";

            this.modal.msg = 'Gracias<br>Tu pedido de '+this.form.quantity+' creditos ha sido generada<br>el ID de tu pedido es: <b>'+response.data.id+'</b><br><br>Recibiras 1 recibo en tu correo de este pedido';
            this.$root.auth();
            
        }).catch((error)=>{
          this.desactivar = false;
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = 'Error al generar orden';
        });

    },
  },
  mounted(){
      var self = this;
      setTimeout(self.loadStripe(), 1000);
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();

    if(this.$root.logged == false){
      this.$router.push("/login");
    }

    this.getPackages();
  }
}
</script>
<style>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>

