import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'home.vue').default },
      // { path: '/analisis-legal-de-documentos', component: require(page+'analisis-legal/index.vue').default, meta:{title:"Analisis legal de documentos"}},
      { path: '/como-te-protegemos', component: require(page+'empresa/proteger.vue').default, meta:{title:"¿Cómo te protegemos?"}},
      { path: '/creditos', component: require(page+'creditos/index.vue').default, meta:{title:"Créditos"}},
      { path: '/contacto', component: require(page+'contacto/index.vue').default, meta:{title:"Contacto"}},
      { path: '/acreditaciones', component: require(page+'empresa/acreditaciones.vue').default, meta:{title:"Acreditaciones"}},
      { path: '/empresa', component: require(page+'empresa/acerca-de.vue').default, meta:{title:"Nuestra empresa"}},
      { path: '/preguntas-frecuentes', component: require(page+'empresa/faqs.vue').default, meta:{title:"Preguntas frecuentes"}},

      // { path: '/contratos', component: require(page+'contratos/results.vue').default, meta:{title:"Contratos"}},
      { path: '/contratos/:id', component: require(page+'contratos/detail.vue').default, meta:{title:"Contrato"}},
      { path: '/contrato/:id', component: require(page+'contratos/validate.vue').default, meta:{title:"Contrato"}},

      { path: '/politicas-empresariales', component: require(page+'text-pages/politicas.vue').default, meta:{title:"Políticas Empresariales"}},
      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"}},
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
      { path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},
      { path: '/registrarse/:id', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},

      { path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
    			{
    				path: '/',
    				component: require(page+'usuario-cuenta/mi-informacion.vue').default,
    				meta:{ title: 'Mi información básica' }
    			},
          {
    				path: 'documentacion',
    				component: require(page+'usuario-cuenta/mi-documentacion.vue').default,
    				meta:{ title: 'Mi documentación' }
    			},
          {
    				path: 'fotografia',
    				component: require(page+'usuario-cuenta/mi-fotografia.vue').default,
    				meta:{ title: 'Mi fotografía' }
    			},
          {
    				path: 'firma',
    				component: require(page+'usuario-cuenta/mi-firma.vue').default,
    				meta:{ title: 'Mi firma' }
    			},
    			{
    				path: 'contrasena',
    				component: require(page+'usuario-cuenta/mi-contrasena.vue').default,
    				meta:{ title: 'Mi Contraseña' }
    			},
          {
    				path: 'nuevo-contrato',
    				component: require(page+'usuario-cuenta/contrato-nuevo.vue').default,
    				meta:{ title: 'Nuevo contrato' }
    			},
          {
    				path: 'contratos',
    				component: require(page+'usuario-cuenta/contractos.vue').default,
    				meta:{ title: 'Contratos' }
    			},
          {
    				path: 'contactos',
    				component: require(page+'usuario-cuenta/contactos.vue').default,
    				meta:{ title: 'Contactos' }
    			},
          {
    				path: 'comprar-creditos',
    				component: require(page+'usuario-cuenta/comprar-creditos.vue').default,
    				meta:{ title: 'Comprar créditos' }
    			},
  	  	]
  		}
	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "HOTDOC, Nos cuidamos", filter: (title)=>{ return title+" - HOTDOC, Nos cuidamos"; } }
);

// export {routes};
export default MyRouter;
