<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper swiper-s1 swiper-desktop" :options="bannersOptions">
        <swiper-slide v-for="(banner, bhInx) in banners.pc" :key="'bhInx-'+bhInx">
          <router-link class="placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" to="">
            <img src="public/images/pages/home/banner.png">
          </router-link>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper swiper-s1 swiper-mobile" :options="bannersOptions">
        <swiper-slide v-for="(banner, bhInx) in banners.movil" :key="'bhInx-'+bhInx">
          <router-link class="placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" to="">
            <img src="public/images/pages/home/banner-m.png">
          </router-link>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="how-works-section">
      <div class="container oversized-container">
        <h2 class="title-s-1">¿Cómo funciona?</h2>

        <div class="row justify-content-center">
          <div class="col-sm-6 col-lg-4 col-how">
            <img src="public/images/pages/home/how-1.png">
            <h5>
              Registra tus datos completos y los de tu acompañante.
            </h5>
          </div>

          <div class="col-sm-6 col-lg-4 col-how">
            <img src="public/images/pages/home/how-2.png">
            <h5>
              Ambas partes firman de manera digital en menos de 3 segundos.
            </h5>
          </div>

          <div class="col-sm-6 col-lg-4 col-how">
            <img src="public/images/pages/home/how-3.png">
            <h5>
              El documento se procesa en unos minutos. Al terminar se envía a tu correo sellado y certificado.
            </h5>
          </div>
        </div>
      </div>
    </section>

    <section class="accreditations-section">
      <div class="container oversized-container">
        <h2 class="title-s-1">Acreditaciones y certificados</h2>

        <div class="row">
          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-1.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de emisión de certificados digitales</h6>
              <!-- <h5 class="date">DOF 20/06/2011</h5> -->
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-2.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de emisión de constancias de conservación de mensajes de datos NOM 151 SCFI 2016</h6>
              <!-- <h5 class="date">DOF 14/12/2011</h5> -->
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-1.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de emisión de sellos digitales de tiempo</h6>
              <!-- <h5 class="date">DOF 14/12/2011</h5> -->
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 box-accreditation-s1">
            <div class="box-image">
              <img src="public/images/pages/contracts/accreditations-2.png">
            </div>

            <div class="box-info">
              <h6 class="title">Servicio de digitalización de documentos en soporte físico como Tercero Legalmente Autorizado TLA</h6>
              <!-- <h5 class="date">DOF 03/12/2019</h5> -->
              <!-- <h5 class="date f-w-400 pt-1">ASESORÍA JURÍDICA GRATUÍTA</h5> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container packages-section">
      <h2 class="title-s-1">Créditos</h2>

      <div class="row">
        <div class="col-12 col-carousel">
          <swiper class="swiper" :options="packsOptions">
            <swiper-slide v-for="(p, phInx) in packages" :key="'phInx-'+phInx">
              <div class="col-12 px-0 box-credit-s1">
                <router-link class="box" to="/creditos">
                  <!-- <div class="col-xl-5 align-self-center col-image">
                    <img :src="p.imageUrl">
                  </div> -->

                  <div class="col-12 col-info-1">
                    <h4 class="num" v-html="p.credits"></h4>
                    <h5 class="name">{{p.name}}</h5>
                    <div class="descr">
                      {{ p.description }}
                    </div>
                    <span class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} MXN</span>
                  </div>

                  <div class="col-12 align-items-center col-info-2">
                    <div class="col col-text">PAGA CON:</div>
                    <div class="col col-image"><img src="public/images/shared/cards-v2.svg"></div>
                  </div>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="agreements-section">
      <div class="container oversized-container">
        <h2 class="title-s-1">Contratos & acuerdos destacados</h2>

        <swiper class="swiper" :options="contractsOptions">
          <swiper-slide v-for="(a, ahInx) in contracts" :key="'ahInx-'+ahInx">
            <div class="col-12 px-0 box-contract-sample-1">
              <router-link class="box" to="/contratos/1">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+a.imageUrl+')' }">
                  <img src="public/images/pages/contracts/img-agreement.png">
                </div>

                <div class="box-info">
                  <div class="inside">
                    <h5>{{ a.title }}</h5>

                    <div class="descr" >
                      <h6 v-html="a.description"></h6>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: {
        pc: [
          //{ imgURL: 'public/images/pages/home/banner-1.jpg', link: '/#/registrarse' },
          //{ imgURL: 'public/images/pages/home/banner-2.jpg', link: '/#/registrarse' },
        ],
        movil: [
          //{ imgURL: 'public/images/pages/home/banner-1-m.jpg', link: '/#/registrarse' },
          //{ imgURL: 'public/images/pages/home/banner-2-m.jpg', link: '/#/registrarse' },
        ],
      },

      packages: [
        //{ id: 1, n: '1', name:'Contrato, acuerdo o documento', imgURL: 'public/images/pages/packages/icon-1.svg', price: '$500.00 MXN',total:500 },
        //{ id: 2, n: '3', name:'Documentos', imgURL: 'public/images/pages/packages/icon-2.svg', price: '$1,000.00 MXN',total:1000 },
        //{ id: 3, n: '999999999', name:'Documentos', n: '<i class="far fa-infinity"></i>', imgURL: 'public/images/pages/packages/icon-3.svg', price: '$3,000.00 MXN',total:3000 },
      ],

      contracts: [
        //{ imgURL: 'public/images/pages/contracts/agreement-1.jpg', name: 'Consentimiento', descr: 'Documento de consentimiento para relación casual' },
        //{ imgURL: 'public/images/pages/contracts/agreement-2.jpg', name: 'Servicio temporal', descr: 'Contratos de servicios temporal' },
        //{ imgURL: 'public/images/pages/contracts/agreement-3.jpg', name: 'Servicios profesionales', descr: 'Contratos de servicios profesionales' },
      ],

      // == Carousels options ==
      bannersOptions: {
        slidesPerView: 1,
        loop: true,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
      },

      packsOptions: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 30,
        loopedSlides: 3,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },

      contractsOptions: {
        slidesPerView: 3,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  },
   methods:{
    getBanners(){
      axios.get(tools.url("/api/banners")).then((response)=>{
          this.banners=response.data;
        }).catch((error)=>{

        });
    },
    getContracts(){
        axios.get(tools.url("/api/contracts")).then((response)=>{
            this.contracts = response.data;
        }).catch(()=>{});
    },
    getPackages(){
        axios.get(tools.url('/api/packages')).then((response)=>{
            this.packages = response.data;
        }).catch((error)=>{

        });
    }

  },

  mounted(){
    this.getBanners();
    this.getContracts();
    this.getPackages();
  }
}
</script>
