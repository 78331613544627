<template lang="html">
  <div id="info-page-s2" class="about-us-page">

    <div class="placed-backg img-fake l" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/bg.jpg)' }"></div>

    <section class="container oversized-container text-justify main-section">
      <div class="row align-items-center">
        <div class="col-lg-6 order-2 order-lg-0 mt-2 mt-lg-0 col-image">
          <!-- <img src="public/images/pages/about-us/image.jpg"> -->
        </div>

        <div class="col-lg-6 col-info">
          <h1 class="title-s-1">SOBRE LA EMPRESA</h1>

          <p>
            En HOTDOC, nos enorgullece contar con un equipo diverso de profesionales expertos en las industrias tecnológica y legal, unidos por un objetivo común: proporcionar a nuestros usuarios una experiencia sin complicaciones al crear contratos de convivencia.
          </p>

          <br />
          <h5>Profesionales de la Industria Tech y Legal</h5>
          <p>
            Nuestro equipo está formado por expertos altamente calificados en tecnología y derecho. Combinamos la experiencia técnica con un profundo conocimiento legal para brindarte la mejor plataforma posible, diseñada para satisfacer las necesidades específicas de la creación de contratos de convivencia.
          </p>

          <br />
          <h5>Eficiencia en un Minuto</h5>
          <p>
            Entendemos que tu tiempo es valioso. Por eso, hemos simplificado y agilizado el proceso de creación de contratos de convivencia para que puedas obtener un documento seguro y legal en tan solo un minuto. Nuestra interfaz intuitiva y eficiente te permite concentrarte en lo que realmente importa: establecer acuerdos claros y sólidos.
          </p>

          <br />
          <h5>Seguridad en el Centro de Todo</h5>
          <p>
            La seguridad es nuestra máxima prioridad. Implementamos tecnologías de vanguardia para proteger tus datos y la confidencialidad de tus acuerdos. Puedes confiar en que tus contratos de convivencia estarán resguardados con las mejores prácticas de seguridad digital.
          </p>

          <br />
          <h5>Compromiso con la Innovación</h5>
          <p>
            En HOTDOC, estamos comprometidos con la innovación constante. Nos esforzamos por mantenernos a la vanguardia de los avances tecnológicos y legales, asegurándonos de que nuestra plataforma refleje las últimas tendencias y mejores prácticas del mercado.
          </p>

          <br />
          <h5>Experiencia Personalizada</h5>
          <p>
            Entendemos que cada situación es única. Por eso, ofrecemos un enfoque personalizado para garantizar que encuentres la solución perfecta para tus necesidades específicas. Nuestro equipo de soporte está disponible para ayudarte en cada paso del camino y responder a cualquier pregunta que puedas tener.
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
