<template lang="html">
  <footer id="footer">
  	<div class="container oversized-container">
			<div class="row">
				<div class="col-lg-3 col-logo">
					<span class="box">
						<router-link to="/">
							<img src="public/images/logo-white.svg">
						</router-link>

						<p class="txt-info">
							Respaldo por la Ley Federal de Firma Electrónica, Código de Comercio y Leyes Estatales de Medios Electrónicos
						</p>
					</span>
				</div>

				<div class="col-lg-9">
					<div class="row">
						<div class="col-sm-6 col-lg-4 col-footer">
							<h5>Información</h5>

							<p class="mt-lg-2 f-w-300"><router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link></p>
              <p class="mt-lg-2 f-w-300"><router-link to="/politicas-empresariales">Políticas Empresariales</router-link></p>
              <p class="mt-lg-2 f-w-300"><router-link to="/terminos-y-condiciones">Términos y condiciones</router-link></p>
							<p class="mt-lg-2 f-w-300"><router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link></p>
						</div>

						<div class="col-sm-6 col-lg-4 col-footer">
							<h5>Mapa del sitio</h5>

							<p class="f-w-300"><router-link to="/acreditaciones">Acreditaciones</router-link></p>
							<!-- <p class="mt-lg-2 f-w-300"><router-link to="/contratos">Catálogo de contratos</router-link></p> -->
              <p class="mt-lg-2 f-w-300"><router-link to="/empresa">Sobre nosotros</router-link></p>
							<p class="mt-lg-2 f-w-300"><router-link to="/contacto">Contacto</router-link></p>
						</div>

						<div class="col-lg-4 col-footer col-copyright align-self-center">
							<p class="mb-3">
								<router-link class="t-150 btn-network" to="/contacto">
									<span><i class="fal fa-envelope"></i></span>
								</router-link>
								<a class="t-150 btn-network" target="_blank" href="#">
									<span><i class="fab fa-instagram"></i></span>
								</a>
								<a class="t-150 btn-network" target="_blank" href="#">
									<span><i class="fab fa-tiktok"></i></span>
								</a>
							</p>

							<p class="p-sm">
								Av. Juárez 624, Adolfo L. Mateos<br />
								C.P. 77667<br />
								Cozumel, Quintana Roo
							</p>
							<p class="mt-3 p-sm">
								Tel. +52 1 987 869 8658
							</p>

              <p class="mt-3 p-apps">
                <span>
                  <h6 class="_t">Descarga nuestra App</h6><br />

                  <a class="btn-app" target="_blank" href="https://play.google.com/store/apps/details?id=com.hotdoc.net">
                    <img src="public/images/shared/google-play.svg">
                  </a>

                  <a class="btn-app" target="_blank" href="https://apps.apple.com/mx/app/hotdoc/id1667068829">
                    <img src="public/images/shared/app-store.svg">
                  </a>
                </span>
              </p>
						</div>
					</div>
				</div>
			</div>
  	</div>
  </footer>
</template>

<script>
export default {
}
</script>
