<template lang="html">
  <div id="info-page-s1">
    <section class="placed-backg banner-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/documents/banner-1.jpg)' }"></section>

    <section class="container text-justify main-section">
      <h1 class="title-s-1">¿Cómo te protegemos?</h1>

      <p>
        En HOTDOC, nos tomamos muy en serio la seguridad y la protección de nuestros usuarios. Nuestra misión es proporcionarte una eficiente y rapida para crear contratos de convivencia entre terceros.
      </p>
      <p>
        Aquí te explicamos cómo te protegemos en cada paso del proceso:
      </p>
      <br />
      <h5>1. Seguridad de la Información</h5>
      <p>
        Utilizamos tecnologías avanzadas de encriptación, firma con sello de tiempo y certificado digital para garantizar que tu información personal y los detalles de tus contratos estén siempre protegidos. Tu privacidad es nuestra prioridad, y nos esforzamos por mantener tus datos seguros en todo momento.
      </p>

      <br />
      <h5>2. Transparencia Total</h5>
      <p>
        Queremos que te sientas seguro al utilizar nuestra plataforma. Por ello, proporcionamos información detallada sobre cómo funciona el proceso de creación de contratos, los términos y condiciones, así como cualquier cambio en nuestra política de privacidad. La transparencia es clave para construir una relación de confianza contigo.
      </p>

      <br />
      <h5>3. Asesoramiento Legal</h5>
      <p>
        Contamos con un equipo de profesionales legales que revisan y actualizan regularmente nuestros modelos de contratos. Esto garantiza que los documentos generados cumplan con las leyes y regulaciones actuales, ofreciéndote un respaldo legal sólido para tu convivencia.
      </p>

      <br />
      <h5>4. Acceso Seguro</h5>
      <p>
        Implementamos medidas de seguridad robustas para proteger tu cuenta. Autenticación de dos factores, monitoreo continuo y actualizaciones de seguridad regulares son solo algunas de las precauciones que tomamos para mantener tu cuenta a salvo de accesos no autorizados.
      </p>

      <br />
      <h5>5. Soporte Personalizado</h5>
      <p>
        Nuestro equipo de soporte está disponible para ayudarte en cada paso del proceso. Si tienes alguna pregunta, inquietud o problema, estamos aquí para brindarte asistencia personalizada y resolver cualquier problema que puedas encontrar.
      </p>

      <br />
      <p>
        En HOTDOC, nos esforzamos por ser tu aliado en la creación de contratos de convivencia seguros y efectivos. Tu confianza es nuestra prioridad número uno, y trabajamos incansablemente para brindarte la tranquilidad que necesitas al gestionar acuerdos importantes.
      </p>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
