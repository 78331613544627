<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="login()">

            <h1 class="mb-3">Iniciar Sesión</h1>

            <b-form-group>
              <b-form-input type="email" v-model="form.email" required placeholder="Correo electrónico"></b-form-input>
            </b-form-group>

            <!-- <b-form-group>
              <b-form-input type="password" v-model="form.password" required placeholder="Contraseña"></b-form-input>
            </b-form-group> -->

            <b-form-group>
              <div class="eye-box" style="position:relative;">
                  <b-form-input
                            id="il-1"
                            size="sm"
                            v-model="form.password"
                            type="password"
                            required
                            placeholder="Contraseña" 
             
                  ></b-form-input>
                  <i style="background-color: #fff;cursor: pointer; padding: 6px 10px; position: absolute;right: 2px;top: 2px; z-index: 4" class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpass, 'fa-eye-slash' : !showpass }"  @click="showpass = !showpass"></i>
              </div>
            </b-form-group>


            <p class="mb-3 text-center">
              <router-link to="/registrarse">¿ No tienes una cuenta? Registrate.</router-link><br><br>
              <router-link to="/recuperar-contrasena">¿Olvidó su contraseña?</router-link>
            </p>

            <b-form-group class="text-center">
              <b-button type="submit" class="btn-s1 bg-black">Acceder</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        {{modal.msg}}
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: '',
        password: ''
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      showpass:false,
    }
  },
  watch:{
    'showpass':function (val) {
          if (val == true) {
              $("#il-1").prop('type','text');
          }
          else if (val == false) {
            $("#il-1").prop('type','password');
          }
      },
  },
  methods: {
    login(){
      this.modal.icon = "";
      this.modal.msg = 'Cargando...';
      this.modal.block = true;
      this.$refs.modal.open();
      
      axios.get(tools.url('/sanctum/csrf-cookie')).then(() => {
         axios.post(tools.url("/api/login"), this.form).then((response)=>{
            this.modal.icon = "";
            this.modal.msg = '';
            this.modal.block = false;
            this.$refs.modal.close();

             this.$parent.user = response.data;
             this.$parent.logged = true;
             this.$root.auth();
             this.$router.push('/usuario');
          }).catch((error)=>{
              console.log(error.response.data.error);
              this.modal.icon = "error";
              this.modal.msg = 'Credenciales incorrectas';
              this.modal.block = false;
              
         });
      });
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="Error desconocido.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
  },

  mounted(){
    if(this.$root.logged){
        this.$router.push("/usuario");
    }
  }
}
</script>
