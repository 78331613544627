<template lang="html">
  <div class="new-contract-page">
    <b-form class="c-f-g-2-wrp sm" @submit="onSubmit">
      <h5 class="main-page-title">Nuevo documento a certificar</h5>
        <div class="box-step-title" style="color: #7e7e7e;">
          <h2 class="step-title"> {{ form.contractdata.title}}</h2>
        </div>

      
      <hr class="c-hr" />

      <!-- Paso 1 -->
      <div class="box-step" v-if="step == 1">
        <div class="box-step-title">
          <h2 class="step-title"><span>1</span> Selecciona el tipo de documento</h2>
        </div>

        <!-- <b-form-group class="cus-f-group-2">
          <b-form-select required v-model="form.type_contracts_id" size="sm">
            <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
            <b-form-select-option :value="1" >Selecciona un formato pre-cargado de nuestro catalogo</b-form-select-option>
            <b-form-select-option :value="4" >Certifica una imagen o documento existente</b-form-select-option>
            <b-form-select-option :value="3" >Llena un formato y certificalo</b-form-select-option>
            <b-form-select-option :value="2" >Crea un contrato desde 0</b-form-select-option>

          </b-form-select>
        </b-form-group> -->

        <b-form-group class="cus-f-group-2" v-if="form.type_contracts_id == 1">
          <b-form-select required v-model="form.contracts_id" size="sm">
            <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
            <b-form-select-option :value="cont.id" v-for="(cont,indx) in contracts" :key="indx">{{cont.title}}</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <div class="row" v-if="form.type_contracts_id == 1">
          <div class="col-12 box-inf-1">
            <label class="label">Tipo de contrato:</label>
            <h6>{{form.contractdata.title}}</h6>
          </div>

          <div class="col-12 box-inf-1">
            <label class="label">Descripción:</label>
            <div v-html="form.contractdata.description"></div>
          </div>
        </div>
        <div class="row" v-if="form.type_contracts_id == 4">
          <div class="col-12 box-inf-1">
            Puedes certificar capturas de pantalla de conversaciones, mensajes de texto, correos electrónicos y/o cualquier otro medio multimedia al que desees darle validez legal.
            </div>
        </div>
       

        <div class="d-block pt-4">
          <!-- <b-button type="submit" class="btn-s1 bg-black">Siguiente</b-button> -->
          <a v-if="form.contracts_id != 888888888" @click="submitStep(2)" class="btn btn-s1 bg-black">Siguiente</a>
          <a v-else @click="submitStep(3)" class="btn btn-s1 bg-black">Siguiente</a>
        
        </div>
      </div>
      <!--  -->

      <!-- Paso 2 -->
      <div class="box-step" v-show="step == 2">
        <div class="box-step-title">
          <!-- <h2 class="step-title"><span>4</span> Claúsulas</h2> -->
            <h2 class="step-title" ><span>2</span> Cuerpo del contrato</h2>
        </div>

    
          <div v-for="(clau,indxc) in form.contractdata.clauses.fields_header" :key="indxc" >
            <!-- <p style="text-align: center;">{{clau.title}}</p> -->
            <!-- <div v-html="clau.description"></div> -->
            <b-form-group class="" :label="clau.title +''" v-if="clau.type == 'campo'">
              <b-form-input type="text" v-model="clau.response" size="sm" required placeholder="" />
            </b-form-group>
            <b-form-group class="" :label="clau.title +''" v-else>
              <b-form-textarea type="text" v-model="clau.response" size="sm" required placeholder="" />
            </b-form-group>
            
          </div>
          <hr>
          <div v-for="(clau,indxc) in form.contractdata.clauses.fields_medio" :key="indxc" >
            <!-- <p style="text-align: center;">{{clau.title}}</p> -->
            <!-- <div v-html="clau.description"></div> -->
            <b-form-group class="" :label="clau.title +'*'" v-if="clau.type == 'campo'">
              <b-form-input type="text" v-model="clau.response" size="sm" required placeholder="" />
            </b-form-group>
            <b-form-group class="" :label="clau.title +'*'" v-else>
              <b-form-textarea type="text" v-model="clau.response" size="sm" required placeholder="" />
            </b-form-group>
            
          </div>
          <hr>
          <div v-for="(clau,indxc) in form.contractdata.clauses.fields_inferior" :key="indxc" >
            <!-- <p style="text-align: center;">{{clau.title}}</p> -->
            <!-- <div v-html="clau.description"></div> -->
            <b-form-group class="" :label="clau.title +'*'" v-if="clau.type == 'campo'">
              <b-form-input type="text" v-model="clau.response" size="sm" required placeholder="" />
            </b-form-group>
            <b-form-group class="" :label="clau.title +'*'" v-else>
              <b-form-textarea type="text" v-model="clau.response" size="sm" required placeholder="" />
            </b-form-group>

            <hr>
          </div>
       

        <div class="d-block pt-4">
         
          <a class="btn btn-s1 bg-black"  @click="step = 1">Anterior</a>

          <a  @click="submitStep(3)" class="btn btn-s1 bg-black">Siguiente</a>
          <!-- <a v-else @click="submitStep(7)" class="btn btn-s1 bg-black">Certificar de forma digital</a> -->
        </div>
      </div>
      <!--  -->

      <!-- Paso 3 -->
      <div class="box-step" v-show="step == 3">
        <div class="box-step-title">
          <h2 class="step-title"><span>3</span> Fotos de indentificación</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-9 mb-4">
              <div class="box-image-uploader" @click="openModalCapture('ine_front')">
                <img class="empty" src="public/images/shared/empty.png">

                <b-form-group class="box-btn-photo" v-show="!form.ine_front">
                  <label class="fake-label" for="img-photo-i1">
                    <div class="inside">
                      <h5>Frente</h5>
                    </div>
                  </label>
                
                </b-form-group>

                <div class="placed-backg box-img-fake" v-bind:style="{ backgroundImage: 'url('+form.ine_front+')' }" v-show="form.ine_front">
                  <div class="inside">
                    <a class="btn btn-s1 bg-black" @click="removeImage('ine_front')">Quitar</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9 mb-4">
              <div class="box-image-uploader" @click="openModalCapture('ine_back')">
                <img class="empty" src="public/images/shared/empty.png">

                <b-form-group class="box-btn-photo" v-show="!form.ine_back">
                  <label class="fake-label" for="img-photo-i1">
                    <div class="inside">
                      <h5>Atras</h5>
                    </div>
                  </label>
                  <!-- <input id="img-photo-i1" name="image" accept="image/*" type="file" @change="onFileChange('ine_back', $event)"> -->
                </b-form-group>

                <div class="placed-backg box-img-fake" v-bind:style="{ backgroundImage: 'url('+form.ine_back+')' }" v-show="form.ine_back">
                  <div class="inside">
                    <a class="btn btn-s1 bg-black" @click="removeImage('ine_back')">Quitar</a>
                  </div>
                </div>
              </div>
            </div>
         
        </div>

        <div class="d-block pt-4">
          <a class="btn btn-s1 bg-black" @click="step = 2">Anterior</a>
          <a @click="submitStep(4)" class="btn btn-s1 bg-black">Siguiente</a>
        </div>
      </div>

      <!-- Paso 4 -->
      <div class="box-step" v-if="step == 4">
        <div class="box-step-title">
          <h2 class="step-title"><span>4</span> Esta SELFIE es únicamente para nuestro archivo, no será compartida con ningún medio. Procura aparecer con suficiente iluminación y que se distiga tu rostro.</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-9 mb-4">
              <div class="box-image-uploader" @click="openModalCapture('selfie')">
                <img class="empty" src="public/images/shared/empty.png">

                <b-form-group class="box-btn-photo" v-show="!form.selfie">
                  <label class="fake-label" for="img-photo-i1">
                    <div class="inside">
                      <h5>Selfie</h5>
                    </div>
                  </label>
                
                </b-form-group>

                <div class="placed-backg box-img-fake" v-bind:style="{ backgroundImage: 'url('+form.selfie+')' }" v-show="form.selfie">
                  <div class="inside">
                    <a class="btn btn-s1 bg-black" @click="removeImage('selfie')">Quitar</a>
                  </div>
                </div>
              </div>
            </div>

        </div>

        <div class="d-block pt-4">
          <a class="btn btn-s1 bg-black" @click="step = 3">Anterior</a>
          <a @click="submitStep(5)" class="btn btn-s1 bg-black">Siguiente</a>
        </div>
      </div>

      <!-- Paso 5 -->
      <div class="box-step" v-if="step == 5">
        <div class="box-step-title">
          <h2 class="step-title"><span>5</span> Revisar</h2>
        </div>

        <div class="box-pdf-preview" >
          <div class="box">
              <div v-html="form.contractdata.header_format_preview"></div><br>
              <div v-html="form.contractdata.medio_format_preview"></div><br>
              <div v-for="(clau,indxc) in form.contractdata.clauses" :key="indxc">
                  <!-- <h6>{{clau.title}}</h6> -->
                  <div v-html="clau.descriptionview"></div>
              </div><br>
              <div v-html="form.contractdata.inferior_format_preview"></div>
            </div>
        </div>

        <div class="d-block pt-4">
          <a class="btn btn-s1 bg-black" @click="step = 4">Anterior</a>
          <a @click="submitStep(6)" class="btn btn-s1 bg-black">Siguiente</a>
        </div>

      </div>

      <!-- Paso 6 -->
      <div class="box-step" v-if="step == 6">
        <div class="box-step-title">
          <h2 class="step-title"><span>6</span> Docummento terminado</h2>
        </div>

        <div class="d-block py-5">
          <div class="d-block box-finished">
            <i class="fal fa-check-circle icon"></i>
            <h6 class="subtitle">Finalizado exitosamente</h6>
          </div>

          <div class="d-block pt-4 text-center">
            <a @click="openSignatureModalSD" class="btn btn-s1 bg-black">Firmar</a>
            <!-- <b-button type="submit" class="btn btn-s1 bg-black"><i class="fas fa-badge-check"></i> Firmar de forma digital</b-button> -->
          </div>
        </div>
      </div>

       <!-- Paso 7 -->
      <div class="box-step" v-if="step == 7">
        <div class="box-step-title">
          <h2 class="step-title"><span>7</span> Docummento terminado</h2>
        </div>

        <div class="d-block py-5">
          

          <div class="d-block pt-4 text-center">
            <a @click="openSignatureModalSDContra" class="btn btn-s1 bg-black">Firmar contraparte</a>
            <!-- <b-button type="submit" class="btn btn-s1 bg-black"><i class="fas fa-badge-check"></i> Firmar de forma digital</b-button> -->
          </div>
        </div>
      </div>

      <!-- Paso 8 -->
      <div class="box-step" v-if="step == 8">
        <div class="box-step-title">
          <!-- <h2 class="step-title"><span>7</span> Contrato firmado</h2> -->
          <h2 class="step-title" ><span>7</span> Contrato firmado</h2>
         
          
        </div>

        <div class="d-block mb-4 box-finished">
          <!-- <i class="fal fa-check-circle icon"></i> -->
          <div class="d-block">
            <success-component></success-component>
          </div>
          <div>
           
            <h6 class="subtitle">{{form.contractdata.title}}</h6>
            <h6 class="subtitle">Contrato firmado exitosamente</h6>
          
          </div>
          <br>
          <div>
          
            <embed :src="order.documentUrl+'#toolbar=0'" width="500" height="450">
          </div>
          <div style="text-align: center;">
              <a class="btn btn-success" target="_blank" :href="order.documentUrl" style="color:white;background-color: #ef413;border-color: #ef413;">Descargar PDF</a>
          </div>

        </div>
      </div>

    </b-form>

     <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">Aceptar</b-button>
      </div>
  </sweet-modal>

  <!-- Modal nuevo contacto -->
  <b-modal modal-class="modal-content-s1" ref="modal-new-contact" title="Agregar contacto" no-close-on-backdrop no-close-on-esc centered hide-footer>
    <b-form @submit="onSubmitNew">

      <b-form-group class="cus-f-group-2" label="Email *">
        <b-form-input type="email" v-model="formNew.email" size="sm" required placeholder="" />
       <!--  <small class="note txt-orange"><i class="fas fa-bell icon"></i> Se enviará un mensaje al email seleccionado para que complete el proceso de registro.</small> -->
      </b-form-group>

      <b-form-group class="cus-f-group-2" label="Nombre completo *">
        <b-form-input type="text" v-model="formNew.name" size="sm" required placeholder="" />
      </b-form-group>

      <b-form-group class="cus-f-group-2" label="Teléfono *">
        <b-form-input type="text" v-model="formNew.phone" size="sm" required placeholder="" maxlength="10" minlength="10" />
      </b-form-group>
      <b-button type="submit" class="btn-s1 bg-black">Agregar contacto</b-button>

    </b-form>
  </b-modal>

  <b-modal modal-class="modal-content-s1" ref="modal-new-image" title="Agregar imagen" no-close-on-backdrop no-close-on-esc centered hide-footer>
  

     

      <b-form-group class="cus-f-group-2" label="Comentarios">
        <b-form-textarea type="email" v-model="formImage.comments" size="sm" required placeholder="" />
       <!--  <small class="note txt-orange"><i class="fas fa-bell icon"></i> Se enviará un mensaje al email seleccionado para que complete el proceso de registro.</small> -->
      </b-form-group>

      <b-form-group class="cus-f-group-2 col-12" label="Imagen">
          <b-form-file plain id="formimage" name="rfc_company" accept="image/*" v-model="formImage.image"></b-form-file>
      </b-form-group>

      <!-- <b-button @click="onSubmitImage" class="btn-s1 bg-black">Agregar contacto</b-button> -->

  
  </b-modal>
  
  <sweet-modal ref="modal_signature_seguridata">
      <div id="content-wrapper" role="main">
            <div id="content" class="canvasDivContainer">
                <div class="signCaptureCanvas"></div>
            </div>
      </div>
  </sweet-modal>

  <sweet-modal ref="modalCapture" title="Tomar foto">

    <div style="text-align: center;" id="imagecapture_inefront" v-show="modalcapture == 'ine_front'">
          <PhotoCapture v-model="form.ine_front"  :data.sync="form.ine_front"/>		       
    </div>
    <div style="text-align: center;" id="imagecapture_ineback" v-show="modalcapture == 'ine_back'">
          <PhotoCapture v-model="form.ine_back"  :data.sync="form.ine_back"/>		       
    </div>
    <div style="text-align: center;" id="imagecapture_selfie" v-show="modalcapture == 'selfie'">
          <PhotoCapture v-model="form.selfie"  :data.sync="form.selfie"/>		       
    </div>
  </sweet-modal>

  </div>
</template>

<script>
import SuccessComponent from '../shared/success-checkmark-component.vue'
import {PhotoCapture, VideoCapture} from 'vue-media-recorder'
export default {
  components: {
    SuccessComponent, PhotoCapture, VideoCapture
  },

  data(){
    return{
      step: 1,

      form: {
        type_contracts_id:1,
        contracts_id:null,
        users_id:null,
        user_contra_id:null,
        password: '',
        contractdata:{
          id:null,
          title:null,
          description:null,
          header_format_preview:null,
          medio_format_preview:null,
          inferior_format_preview:null,
          clauses:{
            fields_header:null,
            fields_medio:null,
            fields_inferior:null,
          }
        },
        contacts:[],
        images:[],
        body:null,

        signaturepoints:[],
        signatureUrl:null,
        ine_front:false,
        ine_back:false,
        selfie:false,

        ine_frente_id:null,
        ine_reverso_id:null,
        selfie_id:null
      },

      formSearch: {
        keyword: null,
      },

      contracts: [],
      contacts:[],

      contactsTwo: [
      ],
      order:{
        stamp:null,
        certificate:null,
        documentUrl:null
      },
      modal:{
          msg:'',
          icon:'',
          block:false,
        },
      formNew:{
        state_id: null,
        town_id: null,
      },
      formImage:{
        comments:null,
        image:null
      },
      customToolbar: [
                    [{ 'font': [] }],
                    [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                    ['bold', 'italic', 'underline'],
                    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }]//,
                    //['image', 'code-block']
                ],
      url_imagecertificate:null,
      formsignature:{
            points:[],
            signatureUrl:null
      },
      formsignaturecontra:{
        points:[],
        base64:null,
        order_id:null
      },
      modalcapture:null
    }
  },
  watch:{
    'form.type_contracts_id':function(val){
      if (val != null) {
          if(val == 2){
            this.form.contracts_id = 0;
          }
          else if(val == 3){
              this.form.contracts_id = 999999999;
          }
          else if(val == 4){
              this.form.contracts_id = 888888888;
          }
      }
    },
      'form.contracts_id':function(val){
          if (val != null) {
            var indx = null;
            for (var i = 0; i < this.contracts.length; i++) {
                if(this.contracts[i]['id'] == val){
                    this.form.contractdata = this.contracts[i];
                    break;
                }
            }
          }
          else{
              this.form.contractdata.id = null;
              this.form.contractdata.title = null;
              this.form.contractdata.description = null;
          }
          
      },
      'form.user_contra_id':function(val){
        for (var i = 0; i < this.contacts.length; i++) {
          this.contacts[i]['active'] = false;
        }
          if (val != null) {
              

              if (this.form.contacts.length > 0) {
                  if(!this.form.contacts.includes(this.contacts[val]['id'])){
                      this.form.contacts.push(this.contacts[val]['id'])
                  }
                  else{
                    var newids = [];
                    for (var i = 0; i < this.form.contacts.length; i++) {
                      if(this.form.contacts[i] != this.contacts[val]['id'])
                      {   

                          newids.push(this.form.contacts[i]);
                      }
                    }
                    this.form.contacts = newids;
                  }
               
              }
              else{

                this.form.contacts.push(this.contacts[val]['id']);
              }
          }
      
        for (var i = 0; i < this.contacts.length; i++) {

            if(this.form.contacts.includes(this.contacts[i]['id'])){
              this.contacts[i]['active'] = true;
            }
        }
        

      },
      'form.ine_front':function (val) {
          if (val != null && val != false) {
            this.$refs.modalCapture.close();
          }
        },
      'form.ine_back':function (val) {
        if (val != null && val != false) {
          this.$refs.modalCapture.close();
        }
      },
      'form.selfie':function (val) {
        if (val != null && val != false) {
          this.$refs.modalCapture.close();
        }
      },
  },
  methods: {
    onSubmitSearch(event) {
      event.preventDefault()
      console.log('xxxx');
    },

    submitStep(nStep){
      if(nStep == 1){ // Evaluar y pasar a sig paso
        this.step = 2;
      }
      if(nStep == 2){ // Evaluar y pasar a sig paso
          if (this.form.contracts_id != null) {
             this.step = 2;
        }
        else{
            alert('Selecciona un contrato');
            return;
        }
       
      }
      if(nStep == 3){ // Evaluar y pasar a sig paso
          this.step = 3;
      }
      if(nStep == 4){ // Evaluar y pasar a sig paso
          this.step = 4;
      }
      if(nStep == 5){ // Evaluar y pasar a sig paso
          var clauses = this.form.contractdata.clauses;
              for (var x = 0; x < this.form.contractdata.clauses['fields_header'].length; x++) {
                  if (x == 0) {
                    this.form.contractdata['header_format_preview'] = this.form.contractdata['header_format'].replace('['+this.form.contractdata.clauses['fields_header'][x]['name']+']',this.form.contractdata.clauses['fields_header'][x]['response']);
           
                  }
                  else{
                    this.form.contractdata['header_format_preview'] = this.form.contractdata['header_format_preview'].replace('['+this.form.contractdata.clauses['fields_header'][x]['name']+']',this.form.contractdata.clauses['fields_header'][x]['response']);
           
                  }
               }     
          
          this.step = 5;
      }
      if(nStep == 6){ // Evaluar y pasar a sig paso
        
        this.step = 6;
      }

      if(nStep == 7){ // Evaluar y pasar a sig paso
        
          this.saveForm();
      }
      if(nStep == 8){ // Evaluar y pasar a sig paso
        
        this.saveFormSignature();
    }
     
    },

    onSubmit(event) {
      event.preventDefault()
      alert('Finalizado');
    },
    getContracts(){
        axios.get(tools.url("/api/contracts")).then((response)=>{
            this.contracts = response.data;
        }).catch(()=>{});
    },
    getContacts(){
        axios.get(tools.url('/api/contacts')).then((response)=>{
          this.contacts = response.data;
        }).catch((error)=>{
           console.log(error);
        });
    },
    saveForm(){
        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        
        //guarda ine de frente
        var file_inefront = this.dataURLtoFile(this.form.ine_front,'ine_front.png');
        var formData = new FormData();
			  formData.append("image", file_inefront);

				var imagenes_id = null;
				var url = "";
				var error = false;

				jQuery.ajax({	   
					  url:"https://hotdoc.com.mx/apiv1/api/sync/save_img",
					  type: "POST",
					  headers: {
					        
					  },
						processData: false,
					  contentType: false,
					  data: formData,
					  async:false,
				})
				.done(function(data, textStatus, jqXHR) {
					    imagenes_id = data.data.id;
				})
				.fail(function(jqXHR, textStatus, errorThrown) {
				})

				this.form.ine_frente_id = imagenes_id;

        //guarda ine de atras
        var file_ineback = this.dataURLtoFile(this.form.ine_back,'ine_back.png');
        var formData = new FormData();
			  formData.append("image", file_ineback);

				var imagenes_id = null;
				var url = "";
				var error = false;

				jQuery.ajax({	   
					  url:"https://hotdoc.com.mx/apiv1/api/sync/save_img",
					  type: "POST",
					  headers: {
					        
					  },
						processData: false,
					  contentType: false,
					  data: formData,
					  async:false,
				})
				.done(function(data, textStatus, jqXHR) {
					    imagenes_id = data.data.id;
				})
				.fail(function(jqXHR, textStatus, errorThrown) {
				})

				this.form.ine_reverso_id = imagenes_id;

         //guarda selfie
         var file_selfie = this.dataURLtoFile(this.form.selfie,'selfie.png');
        var formData = new FormData();
			  formData.append("image", file_selfie);

				var imagenes_id = null;
				var url = "";
				var error = false;

				jQuery.ajax({	   
					  url:"https://hotdoc.com.mx/apiv1/api/sync/save_img",
					  type: "POST",
					  headers: {
					        
					  },
						processData: false,
					  contentType: false,
					  data: formData,
					  async:false,
				})
				.done(function(data, textStatus, jqXHR) {
					    imagenes_id = data.data.id;
				})
				.fail(function(jqXHR, textStatus, errorThrown) {
				})

				this.form.selfie_id = imagenes_id;

        
        var formData = new FormData();
        
        axios.post(tools.url('/api/saveorder'),this.form).then((response)=>{
            this.modal.block = false;
            this.$refs.modal.close();

            this.order = response.data;
            this.step = 7;
            this.$root.auth();
        }).catch((error)=>{
           this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = 'Error al guardar la información';
        });
    },
    saveFormImage(){
        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();

        
        var formData = new FormData();
        formData.append('form', JSON.stringify(this.form));
        formData.append("imagecertificate", jQuery('input[name="imagecertificate"]')[0].files[0]);
        
        axios.post(tools.url('/api/saveorderimage'),formData).then((response)=>{
            this.modal.block = false;
            this.$refs.modal.close();

            this.order = response.data;
            this.step = 8;
            this.$root.auth();
        }).catch((error)=>{
           this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = 'Error al guardar la información';
        });
    },
    onSubmitNew(event) {
        event.preventDefault()
        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();

        axios.post(tools.url('/api/contact'),this.formNew).then((response)=>{
            this.getContacts();
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = 'Contacto guardado correctamente';
            this.formNew = {};
            this.$refs['modal-new-contact'].hide();
            this.getContacts();
            
        }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = error.response.data.msg;
        });
    },
    openModalImage(){
        var aux = {comments:null,image:null};
        this.form.images.push(aux);

   
    },
    deleteImage(indx){
        var newimages = [];
        for (let x = 0; x < this.form.images.length; x++) {
            if(x != indx){
              newimages.push(this.form.images[x]);
            }
          
        }
        this.form.images = newimages;
    },
    onFileChange(target, e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length){
        return;
      }

      // ----- Create image ----
      var image = new Image();
      var reader = new FileReader();

      reader.onload = (e) => {
        //if(target == 'imagePhoto'){
          this.form.images[target].image = e.target.result;
        //}
      };

      reader.readAsDataURL(files[0]);
    },
    getReview(){
      this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();
        axios.post(tools.url('/api/getreview'),this.form).then((response)=>{
            this.form.contractdata.header_format_preview = response.data.header_format_preview;
            this.form.contractdata.medio_format_preview = response.data.medio_format_preview;
            this.form.contractdata.inferior_format_preview = response.data.inferior_format_preview;

            this.modal.block = false;
            this.$refs.modal.close();
        }).catch((error)=>{
          this.modal.block = false;
            this.$refs.modal.close();
        });
    },
    changeImage(event){
    
      const file = event.target.files[0];
      this.url_imagecertificate = URL.createObjectURL(file);
    },
    openSignatureModalSD(){
      this.$refs.modal_signature_seguridata.open();
        $(".canvasDivContainer").empty();
        $(".canvasDivContainer").html('<div class="signCaptureCanvas"></div>');
        var self = this;
        var canvasWidth = 450;
        var canvasHeight = 250;
        var firmaText = "Firme Aquí";
        var limpiarText = "Limpiar";
        var getText = "Confirmar firma";
        var clickX_simple = [];
        var clickY_simple = [];
        var time_simple = [];
        var clickDrag_simple = [];
        var canvas_simple;
        var sigPad = [];


        var canvasDiv = null;
        var button_clear = null;
        var button_getdata = null;
        /*  Función que busca los elementos div que cuenten con la class signCaptureCanvas, 
            para cada div encontrado creará un canvas, un botón para borrar y otro para obtener los datos
        */

        var canvasDivList = $(".signCaptureCanvas");
        $.each(canvasDivList, function (index, value) {

            canvasDiv = value;
            clickX_simple[index] = new Array();
            clickY_simple[index] = new Array();
            time_simple[index] = new Array();
            clickDrag_simple[index] = new Array();
            time_simple[index] = new Array();
            sigPad[index] = new Array();

            // Creación de canvas

            var idSignCaptureCanvas = 'idSignCaptureCanvas' + index;
            canvas_simple = document.createElement('canvas');
            canvas_simple.setAttribute('width', canvasWidth);
            canvas_simple.setAttribute('height', canvasHeight);
            canvas_simple.setAttribute('id', idSignCaptureCanvas);
            canvas_simple.setAttribute('class', 'drawnCanvas');
            canvas_simple.setAttribute('index', index);
            canvasDiv.appendChild(canvas_simple);

              // Creación de botón para mostrar datos capturados en consola
              button_getdata = document.createElement('button');
            button_getdata.setAttribute('class', 'btn-s1 bg-blue');
            button_getdata.setAttribute('type', 'button');
            button_getdata.setAttribute('index', index);
            button_getdata.setAttribute('id', 'idGetButton' + index);
            $(button_getdata).html(getText);
            $(button_getdata).insertAfter(canvasDiv);


            // Creación de botón para limpiar texto

            button_clear = document.createElement('button');
            button_clear.setAttribute('class', 'btn-s1 btn-secondary');
            button_clear.setAttribute('type', 'button');
            button_clear.setAttribute('index', index);
            button_clear.setAttribute('id', 'idCleanButton' + index);
            $(button_clear).html(limpiarText);
            $(button_clear).insertAfter(canvasDiv);

          
            if (typeof G_vmlCanvasManager != 'undefined') {
                canvas_simple = G_vmlCanvasManager.initElement(canvas_simple);
            }

            /*  Instancia de la librería SignaturePad, en él se define el elemento canvas
                y otras propiedades como texto de marca de agua, fuente, grosor del trazo etc.
            */

            sigPad[index] = new SignaturePad( {
                canvas: document.getElementById(idSignCaptureCanvas),   // Elemento canvas
                textFont: 'normal 15px monospace',                      // Fuente
                textStrokeColor: 'transparent',                         // Color de contorno del texto
                textFillColor: '#000',                                  // Color del texto de marca de agua
                brushSize: 2,                                           // Grosor del trazo
                splashText: firmaText,                                  // Texto de la marca de agua
                pointBlackPercent: 0.015,                               // Porcentaje de trazo minímo para aprovar la firma 
                canvasWhitePercent: 0.85,                               // Procentaje del lienzo en blanco que debe haber para aprovar la firma
                dialog: {
                    displayDialog: 'Y',// Desplegar dialog en caso de que se detecte que el trazo no concuerda con la configuración
                    //Mensaje de cuerpo del dialogo
                    bodyText: '¡Ups! se ha detectado que la firma puede no ser correcta, por favor a continuación confirma si la firma mostrada en la parte superior parece ser correcta:',
                    btnDecline: 'Declinar y repetir firma',//Texto de boton para declinar
                    btnConfirm: 'Confirmar y enviar firma',//Texto de boton para confirmar
                    btnConfirmFunction: function () { //function para actuar dependiendo si el usuario presiona el boton confirmar
                        //console.log("btnConfirm pressed");
                        //console.log(sigPad[index].getDataInJSON());
                    },
                    btnDeclineFunction: function () {//function para actuar dependiendo si el usuario presiona el boton declinar
                        var signBackground = document.getElementsByClassName('signature_background')[0];
                        signBackground.firstChild.remove();
                        signBackground.remove();

                        document.querySelector('#idCleanButton0').click();
                    }
                }
            });

            sigPad[index].setSize( canvasWidth, canvasHeight );

            /*  Asignación de funciones a los botones creados previamente.
            */

            document.querySelector( '#idCleanButton' + index ).onclick = function () {
                // Función para borrar el trazo del canvas
                sigPad[index].clear();
            }

            document.querySelector( '#idGetButton' + index ).onclick = function () {
                var statuss = sigPad[index].verify();
                console.log(statuss);
                if (statuss['status'] == 'Rejected') {
                  
                  
                  $(".signature_background").css("display", "none");
                  alert('La firma no es valida');
                }
                else{
                 
                    // Función para obtener los datos X, Y y Dt
                    self.$refs.modal_signature_seguridata.close();
                   
                    self.formsignature.points = sigPad[index].getDataInJSON();
                    self.compareSiganature();
                    //self.formsignature.signatureUrl = sigPad[index].toDataURL();
                }
                
            }
            
        });
    },
    openSignatureModalSDContra(){
      this.$refs.modal_signature_seguridata.open();
        $(".canvasDivContainer").empty();
        $(".canvasDivContainer").html('<div class="signCaptureCanvas"></div>');
        var self = this;
        var canvasWidth = 450;
        var canvasHeight = 250;
        var firmaText = "Firme Aquí";
        var limpiarText = "Limpiar";
        var getText = "Confirmar firma";
        var clickX_simple = [];
        var clickY_simple = [];
        var time_simple = [];
        var clickDrag_simple = [];
        var canvas_simple;
        var sigPad = [];


        var canvasDiv = null;
        var button_clear = null;
        var button_getdata = null;
        /*  Función que busca los elementos div que cuenten con la class signCaptureCanvas, 
            para cada div encontrado creará un canvas, un botón para borrar y otro para obtener los datos
        */

        var canvasDivList = $(".signCaptureCanvas");
        $.each(canvasDivList, function (index, value) {

            canvasDiv = value;
            clickX_simple[index] = new Array();
            clickY_simple[index] = new Array();
            time_simple[index] = new Array();
            clickDrag_simple[index] = new Array();
            time_simple[index] = new Array();
            sigPad[index] = new Array();

            // Creación de canvas

            var idSignCaptureCanvas = 'idSignCaptureCanvas' + index;
            canvas_simple = document.createElement('canvas');
            canvas_simple.setAttribute('width', canvasWidth);
            canvas_simple.setAttribute('height', canvasHeight);
            canvas_simple.setAttribute('id', idSignCaptureCanvas);
            canvas_simple.setAttribute('class', 'drawnCanvas');
            canvas_simple.setAttribute('index', index);
            canvasDiv.appendChild(canvas_simple);

              // Creación de botón para mostrar datos capturados en consola
              button_getdata = document.createElement('button');
            button_getdata.setAttribute('class', 'btn-s1 bg-blue');
            button_getdata.setAttribute('type', 'button');
            button_getdata.setAttribute('index', index);
            button_getdata.setAttribute('id', 'idGetButton' + index);
            $(button_getdata).html(getText);
            $(button_getdata).insertAfter(canvasDiv);


            // Creación de botón para limpiar texto

            button_clear = document.createElement('button');
            button_clear.setAttribute('class', 'btn-s1 btn-secondary');
            button_clear.setAttribute('type', 'button');
            button_clear.setAttribute('index', index);
            button_clear.setAttribute('id', 'idCleanButton' + index);
            $(button_clear).html(limpiarText);
            $(button_clear).insertAfter(canvasDiv);

          
            if (typeof G_vmlCanvasManager != 'undefined') {
                canvas_simple = G_vmlCanvasManager.initElement(canvas_simple);
            }

            /*  Instancia de la librería SignaturePad, en él se define el elemento canvas
                y otras propiedades como texto de marca de agua, fuente, grosor del trazo etc.
            */

            sigPad[index] = new SignaturePad( {
                canvas: document.getElementById(idSignCaptureCanvas),   // Elemento canvas
                textFont: 'normal 15px monospace',                      // Fuente
                textStrokeColor: 'transparent',                         // Color de contorno del texto
                textFillColor: '#000',                                  // Color del texto de marca de agua
                brushSize: 2,                                           // Grosor del trazo
                splashText: firmaText,                                  // Texto de la marca de agua
                pointBlackPercent: 0.015,                               // Porcentaje de trazo minímo para aprovar la firma 
                canvasWhitePercent: 0.85,                               // Procentaje del lienzo en blanco que debe haber para aprovar la firma
                dialog: {
                    displayDialog: 'Y',// Desplegar dialog en caso de que se detecte que el trazo no concuerda con la configuración
                    //Mensaje de cuerpo del dialogo
                    bodyText: '¡Ups! se ha detectado que la firma puede no ser correcta, por favor a continuación confirma si la firma mostrada en la parte superior parece ser correcta:',
                    btnDecline: 'Declinar y repetir firma',//Texto de boton para declinar
                    btnConfirm: 'Confirmar y enviar firma',//Texto de boton para confirmar
                    btnConfirmFunction: function () { //function para actuar dependiendo si el usuario presiona el boton confirmar
                        //console.log("btnConfirm pressed");
                        //console.log(sigPad[index].getDataInJSON());
                    },
                    btnDeclineFunction: function () {//function para actuar dependiendo si el usuario presiona el boton declinar
                        var signBackground = document.getElementsByClassName('signature_background')[0];
                        signBackground.firstChild.remove();
                        signBackground.remove();

                        document.querySelector('#idCleanButton0').click();
                    }
                }
            });

            sigPad[index].setSize( canvasWidth, canvasHeight );

            /*  Asignación de funciones a los botones creados previamente.
            */

            document.querySelector( '#idCleanButton' + index ).onclick = function () {
                // Función para borrar el trazo del canvas
                sigPad[index].clear();
            }

            document.querySelector( '#idGetButton' + index ).onclick = function () {
                var statuss = sigPad[index].verify();
                console.log(statuss);
                if (statuss['status'] == 'Rejected') {
                  
                  
                  $(".signature_background").css("display", "none");
                  alert('La firma no es valida');
                }
                else{
                 
                    // Función para obtener los datos X, Y y Dt
                    self.$refs.modal_signature_seguridata.close();
                   
                    self.formsignaturecontra.points = sigPad[index].getDataInJSON();
                    self.formsignaturecontra.base64 = sigPad[index].toDataURL();
                    self.saveFormSignature();
                    //self.formsignature.signatureUrl = sigPad[index].toDataURL();
                }
                
            }
            
        });
    },
    compareSiganature(){
        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();
        axios.post(tools.url('/api/comparesignature'),this.formsignature).then((response)=>{
            if (response.data.status == true) {
              if (response.data.prediction > 60) {
                  //this.modal.block = false;
                  //this.$refs.modal.close();
                  this.submitStep(7);
              }
              else{
                  this.modal.block = false;
                  this.$refs.modal.close();
                  alert('La firma no coincide con la firma registrada anteriormente, intenta nuevamente');
                  this.openSignatureModalSD();
              }
              
            }
            else{
                this.modal.block = false;
                this.$refs.modal.close();
                alert('La firma no coincide con la firma registrada anteriormente, intenta nuevamente');
                this.openSignatureModalSD();
            } 
            
        }).catch((error)=>{
            this.modal.block = false;
            this.$refs.modal.close();
            alert('Error al comparar la firma, no es similar a la firma inicial que realizaste y que nuestro algoritmo toma de referencia.');
            this.openSignatureModalSD();
        });
    },
    openModalCapture(type){
        this.$refs.modalCapture.open();
  
        
        this.modalcapture = type;
        $('.photo-capture h1').text('');
        $('.photo-capture .photo-capture-actions .flex-center').text('Capturar');
    },
   
    removeImage: function (target) {
      if(target == 'ine_front'){
        this.form.ine_front = false;
      }
      if(target == 'ine_back'){
        this.form.ine_back = false;
      }
      if(target == 'selfie'){
        this.form.selfie = false;
      }
    },
    saveFormSignature(){
        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();
        this.formsignaturecontra.order_id = this.order.id;
        axios.post(tools.url('/api/saveordersignature'),this.formsignaturecontra).then((response)=>{
            this.modal.block = false; 
            this.$refs.modal.close();

            this.order = response.data;
            this.step = 8;
            this.$root.auth();
        }).catch((error)=>{
           this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = 'Error al guardar la información';
        });
    },
    dataURLtoFile(dataurl, filename) {
      
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
    
      return new File([u8arr], filename, {type:mime});
    }
  },
  mounted(){
    if(this.$root.logged == false){
         this.$router.push("/login");
     }
      this.getContracts();
      this.getContacts();
      $('.photo-capture h1').text('');
        $('.photo-capture .photo-capture-actions .flex-center').text('Capturar');
  }
}
</script>

<style>
.tableclass {display:block; }
.rowtable { display:block;}
.celltable {display:inline-block;}
.cellsubtableth {
  background-color: #dddddd;
  display:inline-block;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.cellsubtable {
  display:inline-block;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.bg-blue{
  background-color: #ef4136;
  border-color:  #ef4136;
}
</style>