<template lang="html">
  <div id="contact-page">

    <section class="container main-section">
      <div class="row align-items-center">
        <div class="col-12 col-title">
          <h1 class="title-s-1">Contacto</h1>
        </div>

        <div class="col-lg-6 col-info">
          <h5 class="title">¿Tienes preguntas?<br />Envíanos un mensaje o comunicate con nosotros.</h5>

          <h5 class="mt-3 subtitle">Dirección</h5>
          <p>
            Av. Juárez 624, Adolfo L. Mateos<br />
            C.P. 77667<br />
            Cozumel, Quintana Roo
          </p>

          <h5 class="mt-3 subtitle">Teléfono</h5>
          <p>
            Tel. +52 1 987 869 8658
          </p>

          <h5 class="mt-3 subtitle">Correo</h5>
          <p>
            contacto@hotdoc.com.mx
          </p>

          <h5 class="mt-3 subtitle">Síguenos</h5>
          <p>
            <a class="btn-network mr-1" href="#"><i class="fab fa-facebook-square"></i></a>
            <a class="btn-network ml-1" href="#"><i class="fab fa-twitter-square"></i></a>
          </p>
        </div>

        <div class="col-lg-6 col-form">
          <b-form @submit="onSubmit">
            <div class="row">
              <b-form-group class="cus-f-group-1 col-12" label="Nombre completo">
                <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Correo electrónico">
                <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Teléfono">
                <b-form-input type="text" v-model="form.phone" minlength="10" maxlength="10" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Mensaje">
                <b-form-textarea
                  v-model="form.msg"
                  placeholder=""
                  rows="5"
                  max-rows="5"
                ></b-form-textarea>
              </b-form-group>

              <div class="col-12">
                <b-button type="submit" class="btn-s1 bg-black w-100">ENVIAR</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    }
  },
}
</script>
