<template lang="html">
  <div class="d-flex account-page">
    <div class="container">

      <div class="row">
        <div class="col-xl-10 offset-xl-1 main-box">

          <div class="row">
            <div class="col-12 px-2">
              <h1 class="mb-3 page-title">{{ title }}</h1>
            </div>
            <div class="col-lg-4 px-2 col-menu">
              <div class="white-box">
                <div class="box-profile">
                  <div class="d-block mt-4 pb-3">
                    <span class="fa-stack fa-4x user-icon">
                      <div class="img-photo" v-bind:style="{ backgroundImage: 'url('+$root.user.imageUrl+')' }"></div>
                      <!-- <i class="fas fa-circle fa-stack-2x"></i> -->
                      <!-- <i class="fas fas fa-user fa-stack-1x fa-inverse"></i> -->
                    </span>

                    <h5 class="txt-username">Bienvenido {{ $root.user.name }}</h5>
                  </div>
                </div>

                <hr />

                <div class="box-menu">
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario"><i class="far fa-edit"></i> Mi información básica</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario/documentacion"><i class="far fa-folder"></i> Mi documentación</router-link>
                  </p>
                  <!-- <p class="item">
                    <router-link class="btn-menu" to="/usuario/fotografia"><i class="far fa-camera"></i> Mi foto de perfil</router-link>
                  </p> -->
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario/firma"><i class="far fa-signature"></i> Mi firma</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario/contrasena"><i class="far fa-lock-alt"></i> Mi contraseña</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario/contratos"><i class="fas fa-cabinet-filing"></i> Contratos <span style="border-radius: 50%; border:solid 1px;padding-left: 5px;padding-right: 5px;padding-top: 1px;padding-bottom: 1px;">{{$root.user.total_contracts}}</span></router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario/contactos"><i class="far fa-address-book"></i> Directorio de contactos</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario/comprar-creditos"><i class="fal fa-credit-card"></i> Comprar créditos</router-link>
                  </p>
                  <p class="item">
                    <a class="btn-menu" v-on:click="logout()" style="cursor: pointer;"><i class="far fa-sign-out-alt"></i> Cerrar sesión</a>
                  </p>
                </div>

              </div>
            </div>

            <div class="col-lg px-2 col-page-content">
              <div class="white-box">

                <router-view></router-view>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      title: 'Mi cuenta'
    }
  },

  methods:{
    getUrlName(){
      var urlName = this.$route.path;

      if(urlName == '/usuario'){ this.title = 'Mi información básica' }
      if(urlName == '/usuario/documentacion'){ this.title = 'Mi documentación' }
      if(urlName == '/usuario/fotografia'){ this.title = 'Mi fotografía' }
      if(urlName == '/usuario/firma'){ this.title = 'Mi firma' }
      if(urlName == '/usuario/firma'){ this.title = 'Mi firma' }
      if(urlName == '/usuario/contrasena'){ this.title = 'Mi Contraseña' }
      if(urlName == '/usuario/contratos'){ this.title = 'Contratos' }
      if(urlName == '/usuario/nuevo-contrato'){ this.title = 'Nuevo contrato' }
      if(urlName == '/usuario/contactos'){ this.title = 'Contactos' }
      if(urlName == '/usuario/comprar-creditos'){ this.title = 'Comprar créditos' }
    },

    logout(){
        axios.post(tools.url("/api/logout")).then((response)=>{
            this.$parent.user = {};
            this.$parent.logged = false;
            this.$router.push('/login');
        }).catch(()=>{});
    },
  },

  beforeMount(){
    this.getUrlName();
  },

  watch: {
    $route(to, from) {
      this.getUrlName();
    },
  },

  mounted(){
    if(this.$root.logged == false){
         this.$router.push("/login");
     }
     this.$root.auth();
  }
}
</script>
