<template lang="html">
  <div id="info-page-s1">

    <section class="container oversized-container text-justify main-section">
      <h1 class="title-s-1">Preguntas frecuentes</h1>

      <p v-html="content.body"></p>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/faqs')).then((response)=>{
        this.content = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
     this.getContent();
  }
}
</script>

