<template lang="html">
  <div class="contracts-page">
    <div class="row align-items-center">
      <div class="col-7">
        <h5 class="d-inline-block main-page-title">Documentos a certificar</h5>
      </div>

      <div class="col-5 text-center" style="text-align: right !important;">
        <router-link class="btn btn-sm btn-s1 bg-black" to="/usuario/nuevo-contrato" v-if="$root.user.credits > 0"><i class="fal fa-cabinet-filing"></i> Nuevo documento a certificar</router-link>
        <p v-if="$root.user.credits > 0">Disponibles: {{$root.user.credits}}</p>

        <router-link class="btn btn-sm btn-s1 bg-black" to="/creditos" v-else><i class="fal fa-credit-card"></i> Comprar creditos</router-link>
      </div>
   
      <div class="col-5 text-center" style="text-align: right !important;">
      </div>
    </div>

    <hr class="c-hr" />

    <div class="box-contracts">

      <div class="tab">
        <button class="tablinks" style="width:25%;padding: 14px 10px !important;" :class="active1" @click="active = 1">Esperando firma<br><span style="border-radius: 50%; border:solid black 1px;padding-left: 5px;padding-right: 5px;padding-top: 1px;padding-bottom: 1px;">{{contracts.waiting_signature.length}}</span></button>
        <button class="tablinks" style="width:25%;padding: 14px 10px !important;" :class="active2" @click="active = 2">Pedientes por firmar<br><span style="border-radius: 50%; border:solid black 1px;padding-left: 5px;padding-right: 5px;padding-top: 1px;padding-bottom: 1px;">{{contracts.pending_signature.length}}</span></button>
        <button class="tablinks" style="width:25%;padding: 14px 10px !important;" :class="active3" @click="active = 3">Firmados<br><span style="border-radius: 50%; border:solid black 1px;padding-left: 5px;padding-right: 5px;padding-top: 1px;padding-bottom: 1px;">{{contracts.signatured.length}}</span></button>
        <button class="tablinks" style="width:25%;padding: 14px 10px !important;" :class="active4" @click="active = 4">Cancelados<br><span style="border-radius: 50%; border:solid black 1px;padding-left: 5px;padding-right: 5px;padding-top: 1px;padding-bottom: 1px;">{{contracts.canceled.length}}</span></button>

      </div>
      <div class="box-contract" v-for="(c, cInx) in contracts.waiting_signature" :key="'cInx-'+cInx" v-if="active == 1">

        <div class="col-lg-12 box">
          <label class="label">ID:</label>
          <h6>HD000{{ c.id }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Tipo de documento:</label>
          <h6>{{ c.contrac_name }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Fecha de realización:</label>
          <h6>{{ c.created }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Contratante:</label>
          <h6>{{ c.user_name }}</h6>
        </div>
        <div class="col-6 box">
          <label class="label">Contraparte:</label>
          <h6 v-for="(contra,indxx) in c.users_contra">{{ contra.name }}</h6>
        </div>

        <!-- <div class="col-6 box">
          <label class="label">Sello de tempo:</label>
          <h6>{{ c.stamp }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Certificado digital:</label>
          <h6>{{ c.certificate }}</h6>
        </div> -->

        <div class="col-6 box">
          <label class="label">Fecha de firma de {{$root.user.name}}:</label>
          <h6>{{ c.date_signature_user }}</h6>
        </div>

        <div class="col-6 box"  v-for="(contra,indxx) in c.users_contra">
          <label class="label">Fecha de firma de {{ contra.name }}:</label>
          <h6 v-if="contra.date_signature_user != null">{{ contra.date_signature_user }}</h6>
          <h6 v-else style="color:red">Pendiente <button @click="sendEmail(c.id,contra.id)" class="btn btn-sm btn-s1 bg-black">Reenviar</button></h6>
        </div>


        <!-- <div class="col-12 box">
            <a :href="c.documentUrl" target="_blank" class="btn btn-sm btn-s1 bg-black" style="width: 100%;"><i class="fas fa-file-pdf"></i> Descargar pdf</a>
        </div> -->

        


        <div class="col-12 box" v-if="$root.user.id == c.user_contra_id && c.status == 'Pendiente de firma'">
          <button class="btn btn-s1 bg-black" @click="openSignatureModalSD(c.id,c.documentUrl)">Firmar</button>
        </div>
      </div>
      <div class="box-contract" v-for="(c, cInx) in contracts.pending_signature" :key="'cInx-'+cInx" v-if="active == 2">

        <div class="col-lg-12 box">
          <label class="label">ID:</label>
          <h6>HD000{{ c.id }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Tipo de documento:</label>
          <h6>{{ c.contrac_name }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Fecha de realización:</label>
          <h6>{{ c.created }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Contratante:</label>
          <h6>{{ c.user_name }}</h6>
        </div>
        <div class="col-6 box">
          <label class="label">Contraparte:</label>
          <h6 v-for="(contra,indxx) in c.users_contra">{{ contra.name }}</h6>
        </div>

        <!-- <div class="col-6 box">
          <label class="label">Sello de tempo:</label>
          <h6>{{ c.stamp }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Certificado digital:</label>
          <h6>{{ c.certificate }}</h6>
        </div> -->

        <div class="col-6 box">
          <label class="label">Fecha de firma de {{$root.user.name}}:</label>
          <h6>{{ c.date_signature_user }}</h6>
        </div>

        <div class="col-6 box"  v-for="(contra,indxx) in c.users_contra">
          <label class="label">Fecha de firma de {{ contra.name }}:</label>
          <h6 v-if="contra.date_signature_user != null">{{ contra.date_signature_user }}</h6>
          <h6 v-else style="color:red">Pendiente</h6>
        </div>


        <!-- <div class="col-12 box">
            <a :href="c.documentUrl" target="_blank" class="btn btn-sm btn-s1 bg-black" style="width: 100%;"><i class="fas fa-file-pdf"></i> Descargar pdf</a>
        </div> -->

        <div class="col-12 box" v-if="c.status == 'Pendiente de firma'">
          <button class="btn btn-s1 bg-black" @click="openSignatureModalSD(c.id,c.documentUrl)">Firmar</button>
        </div>
      </div>
      <div class="box-contract" v-for="(c, cInx) in contracts.signatured" :key="'cInx-'+cInx" v-if="active == 3">

        <div class="col-lg-12 box">
          <label class="label">ID:</label>
          <h6>HD000{{ c.id }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Tipo de documento:</label>
          <h6>{{ c.contrac_name }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Fecha de realización:</label>
          <h6>{{ c.created }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Contratante:</label>
          <h6>{{ c.user_name }}</h6>
        </div>
        <div class="col-6 box" v-if="c.images_id == null">
          <label class="label">Contraparte:</label>
          <h6 v-for="(contra,indxx) in c.users_contra">{{ contra.name }}</h6>
        </div>

        <!-- <div class="col-6 box">
          <label class="label">Sello de tempo:</label>
          <h6>{{ c.stamp }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Certificado digital:</label>
          <h6>{{ c.certificate }}</h6>
        </div> -->

        <div class="col-6 box"  v-if="c.images_id != null">
          <label class="label">Fecha de firma de {{$root.user.name}}:</label>
          <h6>{{ c.date_signature_user }}</h6>
        </div>

        <div class="col-6 box"  v-for="(contra,indxx) in c.users_contra">
          <label class="label">Fecha de firma de {{ contra.name }}:</label>
          <h6 v-if="contra.date_signature_user != null">{{ contra.date_signature_user }}</h6>
          <h6 v-else style="color:red">Pendiente</h6>
        </div>

        
        <div class="col-12 box">
            <a :href="c.documentUrl" target="_blank" class="btn btn-sm btn-s1 bg-red" style="width: 100%;background-color: #ef4136;"><i class="fas fa-file-pdf"></i> Descargar pdf</a>
        </div>

        <div class="col-12 box" v-if="c.documentUrlNom != null">
            <a :href="c.documentUrlNom" target="_blank" class="btn btn-sm btn-s1 bg-red" style="width: 100%;background-color: #ef4136;"><i class="fas fa-asterisk"></i> Descargar NOM</a>
        </div>
        <div class="col-12 box" v-if="c.documentUrlNomData != null">
            <a :href="c.documentUrlNomData" target="_blank" class="btn btn-sm btn-s1 bg-red" style="width: 100%;background-color: #ef4136;"><i class="fas fa-file-alt"></i> Descargar NOM PDF</a>
        </div>
        
        <div class="col-6 box" v-if="c.user_id == $root.user.id"></div>
        <div class="col-6 box" v-if="c.user_id == $root.user.id">
            <a @click="cancelContract(c.id)" class="btn btn-sm btn-s1" style="width: 100%;background-color: red;"><i class="fas fa-times-circle"></i> Cancelar contrato</a>
        </div>


      </div>
      <div class="box-contract" v-for="(c, cInx) in contracts.canceled" :key="'cInx-'+cInx" v-if="active == 4">

        <div class="col-lg-12 box">
          <label class="label">ID:</label>
          <h6>HD000{{ c.id }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Tipo de documento:</label>
          <h6>{{ c.contrac_name }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Fecha de realización:</label>
          <h6>{{ c.created }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Contratante:</label>
          <h6>{{ c.user_name }}</h6>
        </div>
        <div class="col-6 box" v-if="c.images_id == null">
          <label class="label">Contraparte:</label>
          <h6 v-for="(contra,indxx) in c.users_contra">{{ contra.name }}</h6>
        </div>

        <!-- <div class="col-6 box">
          <label class="label">Sello de tempo:</label>
          <h6>{{ c.stamp }}</h6>
        </div>

        <div class="col-6 box">
          <label class="label">Certificado digital:</label>
          <h6>{{ c.certificate }}</h6>
        </div> -->

        <div class="col-6 box"  v-if="c.images_id != null">
          <label class="label">Fecha de firma de {{$root.user.name}}:</label>
          <h6>{{ c.date_signature_user }}</h6>
        </div>

        <div class="col-6 box"  v-for="(contra,indxx) in c.users_contra">
          <label class="label">Fecha de firma de {{ contra.name }}:</label>
          <h6 v-if="contra.date_signature_user != null">{{ contra.date_signature_user }}</h6>
          <h6 v-else style="color:red">Pendiente</h6>
        </div>


        <div class="col-lg-6 box">
          <label class="label">Folio de cancelación:</label>
          <h6>{{ c.id_cancel }}</h6>
        </div>

        <div class="col-lg-6 box">
          <label class="label">Fecha de cancelación:</label>
          <h6>{{ c.date_cancel }}</h6>
        </div>
        
        <!-- <div class="col-12 box">
            <a :href="c.documentUrl" target="_blank" class="btn btn-sm btn-s1 bg-black" style="width: 100%;"><i class="fas fa-file-pdf"></i> Descargar pdf</a>
        </div> -->
        


      </div>

    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      <div v-html="modal.msg"></div>
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">Aceptar</b-button>
      </div>
  </sweet-modal>

  <sweet-modal ref="modal_signature_seguridata" >
     <p style="text-align: center;">Este documento no tiene validez hasta estar firmado completamente</p>
      <embed :src="documentUrl_save+'#toolbar=0'" width="500" height="450" >
      <div id="content-wrapper" role="main">
            <div id="content" class="canvasDivContainer">
                <div class="signCaptureCanvas"></div>
            </div>
      </div>
  </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      contracts: {
        waiting_signature:[],
        pending_signature:[],
        signatured:[],
        canceled:[],
      },
      modal:{
          msg:'',
          icon:'',
          block:false,
        },
      active:1,
      formsignature:{
            points:[],
            signatureUrl:null
        },
        id_save:null,
        documentUrl_save:null
    }
  },
  computed:{
      active1: function()
      {
        return (this.active == 1) ? 'active' : '';
      },

      active2: function()
      {
        return (this.active == 2) ? 'active' : '';
      },
      active3: function()
      {
        return (this.active == 3) ? 'active' : '';
      },
      active4: function()
      {
        return (this.active == 4) ? 'active' : '';
      }
    },

  methods: {
    getContracts(){
        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();

        axios.get(tools.url('/api/mycontracts')).then((response)=>{
          this.contracts = response.data;

          this.modal.icon = "";
          this.modal.msg = '';
          this.modal.block = false;
          this.$refs.modal.close();
        }).catch((error)=>{
           console.log(error);
           this.modal.icon = "";
            this.modal.msg = 'Error al consultar los contratos';
            this.modal.block = false;
            this.$refs.modal.close();
        });
    },
    getContractsTwo(){
        axios.get(tools.url('/api/mycontracts')).then((response)=>{
          this.contracts = response.data;
        }).catch((error)=>{
        });
    },
    saveSignature(id){
      this.modal.msg = 'Cargando...';
        /*this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();*/
        axios.get(tools.url('/api/user/saveSignatureContracts/'+id)).then((response)=>{
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = 'Contrato firmado correctamente <br>';

          this.getContractsTwo();
        }).catch((error)=>{
           this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = 'Error al guardar la información';
        });
    },
    cancelContract(id){
      let text = "¿Seguro que deseas cancelar este contrato?";

      if (confirm(text) == true) {
          this.modal.icon = "";
          this.modal.msg = 'Cargando...';
          this.modal.block = true;
          this.$refs.modal.open();
          axios.get(tools.url('/api/user/cancelContracts/'+id)).then((response)=>{
              this.modal.block = false;
              this.modal.icon = "success";
              this.modal.msg = 'Contrato cancelado correctamente <br><a href="'+ response.data.documentUrl +'" target="_blank" class="btn btn-sm btn-s1 bg-black" style="width: 40%;"><i class="fas fa-file-pdf"></i> Descargar pdf</a>';

            this.getContractsTwo();
          }).catch((error)=>{
            this.modal.block = false;
              this.modal.icon = "error";
              this.modal.msg = 'Error al guardar la información';
          });
      } else {
       
      }
    },
    sendEmail(orders_id,user_id){
        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();
        var formdata = {
            'orders_id':orders_id,
            'order_contacts_id':user_id
        };
        axios.post(tools.url('/api/user/sedEmail'),formdata).then((response)=>{
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = 'Correo enviado correctamente';

          this.getContractsTwo();
        }).catch((error)=>{
           this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = 'Error al guardar la información';
        });
    },
    openSignatureModalSD(id,documentUrl){
      this.id_save = id;
      this.documentUrl_save = documentUrl;

      
      this.$refs.modal_signature_seguridata.open();
        $(".canvasDivContainer").empty();
        $(".canvasDivContainer").html('<div class="signCaptureCanvas"></div>');
        var self = this;
        var canvasWidth = 450;
        var canvasHeight = 250;
        var firmaText = "Firme Aquí";
        var limpiarText = "Limpiar";
        var getText = "Confirmar firma";
        var clickX_simple = [];
        var clickY_simple = [];
        var time_simple = [];
        var clickDrag_simple = [];
        var canvas_simple;
        var sigPad = [];


        var canvasDiv = null;
        var button_clear = null;
        var button_getdata = null;
        /*  Función que busca los elementos div que cuenten con la class signCaptureCanvas, 
            para cada div encontrado creará un canvas, un botón para borrar y otro para obtener los datos
        */

        var canvasDivList = $(".signCaptureCanvas");
        $.each(canvasDivList, function (index, value) {

            canvasDiv = value;
            clickX_simple[index] = new Array();
            clickY_simple[index] = new Array();
            time_simple[index] = new Array();
            clickDrag_simple[index] = new Array();
            time_simple[index] = new Array();
            sigPad[index] = new Array();

            // Creación de canvas

            var idSignCaptureCanvas = 'idSignCaptureCanvas' + index;
            canvas_simple = document.createElement('canvas');
            canvas_simple.setAttribute('width', canvasWidth);
            canvas_simple.setAttribute('height', canvasHeight);
            canvas_simple.setAttribute('id', idSignCaptureCanvas);
            canvas_simple.setAttribute('class', 'drawnCanvas');
            canvas_simple.setAttribute('index', index);
            canvasDiv.appendChild(canvas_simple);

              // Creación de botón para mostrar datos capturados en consola
              button_getdata = document.createElement('button');
            button_getdata.setAttribute('class', 'btn-s1 bg-red');
            button_getdata.setAttribute('type', 'button');
            button_getdata.setAttribute('index', index);
            button_getdata.setAttribute('id', 'idGetButton' + index);
            $(button_getdata).html(getText);
            $(button_getdata).insertAfter(canvasDiv);


            // Creación de botón para limpiar texto

            button_clear = document.createElement('button');
            button_clear.setAttribute('class', 'btn-s1 btn-secondary');
            button_clear.setAttribute('type', 'button');
            button_clear.setAttribute('index', index);
            button_clear.setAttribute('id', 'idCleanButton' + index);
            $(button_clear).html(limpiarText);
            $(button_clear).insertAfter(canvasDiv);

          
            if (typeof G_vmlCanvasManager != 'undefined') {
                canvas_simple = G_vmlCanvasManager.initElement(canvas_simple);
            }

            /*  Instancia de la librería SignaturePad, en él se define el elemento canvas
                y otras propiedades como texto de marca de agua, fuente, grosor del trazo etc.
            */

            sigPad[index] = new SignaturePad( {
                canvas: document.getElementById(idSignCaptureCanvas),   // Elemento canvas
                textFont: 'normal 15px monospace',                      // Fuente
                textStrokeColor: 'transparent',                         // Color de contorno del texto
                textFillColor: '#000',                                  // Color del texto de marca de agua
                brushSize: 2,                                           // Grosor del trazo
                splashText: firmaText,                                  // Texto de la marca de agua
                pointBlackPercent: 0.015,                               // Porcentaje de trazo minímo para aprovar la firma 
                canvasWhitePercent: 0.85,                               // Procentaje del lienzo en blanco que debe haber para aprovar la firma
                dialog: {
                    displayDialog: 'Y',// Desplegar dialog en caso de que se detecte que el trazo no concuerda con la configuración
                    //Mensaje de cuerpo del dialogo
                    bodyText: '¡Ups! se ha detectado que la firma puede no ser correcta, por favor a continuación confirma si la firma mostrada en la parte superior parece ser correcta:',
                    btnDecline: 'Declinar y repetir firma',//Texto de boton para declinar
                    btnConfirm: 'Confirmar y enviar firma',//Texto de boton para confirmar
                    btnConfirmFunction: function () { //function para actuar dependiendo si el usuario presiona el boton confirmar
                        //console.log("btnConfirm pressed");
                        //console.log(sigPad[index].getDataInJSON());
                    },
                    btnDeclineFunction: function () {//function para actuar dependiendo si el usuario presiona el boton declinar
                        var signBackground = document.getElementsByClassName('signature_background')[0];
                        signBackground.firstChild.remove();
                        signBackground.remove();

                        document.querySelector('#idCleanButton0').click();
                    }
                }
            });

            sigPad[index].setSize( canvasWidth, canvasHeight );

            /*  Asignación de funciones a los botones creados previamente.
            */

            document.querySelector( '#idCleanButton' + index ).onclick = function () {
                // Función para borrar el trazo del canvas
                sigPad[index].clear();
            }

            document.querySelector( '#idGetButton' + index ).onclick = function () {
                var statuss = sigPad[index].verify();
                console.log(statuss);
                if (statuss['status'] == 'Rejected') {
                  
                  
                  $(".signature_background").css("display", "none");
                  alert('La firma no es valida');
                }
                else{
                 
                    // Función para obtener los datos X, Y y Dt
                    self.$refs.modal_signature_seguridata.close();
                   
                    self.formsignature.points = sigPad[index].getDataInJSON();
                    self.compareSiganature();
                    //self.formsignature.signatureUrl = sigPad[index].toDataURL();
                }
                
            }
            
        });
    },
    compareSiganature(){
        this.modal.icon = "";
        this.modal.msg = 'Validando firma...';
        this.modal.block = true;
        this.$refs.modal.open();
        axios.post(tools.url('/api/comparesignature'),this.formsignature).then((response)=>{
            if (response.data.status == true) {
              if (response.data.prediction > 60) {
                  //this.modal.block = false;
                  //this.$refs.modal.close();
                  this.saveSignature(this.id_save);
              }
              else{
                  this.modal.block = false;
                  this.$refs.modal.close();
                  alert('La firma no coincide con la firma registrada anteriormente, intenta nuevamente');
                  this.openSignatureModalSD(this.id_save,this.documentUrl_save);
              }
              
            }
            else{
                this.modal.block = false;
                this.$refs.modal.close();
                alert('La firma no coincide con la firma registrada anteriormente, intenta nuevamente');
                this.openSignatureModalSD(this.id_save,this.documentUrl_save);
            } 
            
        }).catch((error)=>{
              this.modal.block = false;
              this.$refs.modal.close();
              alert('Error al comparar la firma, no es similar a la firma inicial que realizaste y que nuestro algoritmo toma de referencia.');
              this.openSignatureModalSD(this.id_save,this.documentUrl_save);
        });
    }
  },
  mounted(){
    if(this.$root.logged == false){
         this.$router.push("/login");
     }
    this.getContracts();
  }
}
</script>
<style scoped="">
body {font-family: Arial;}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 13px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.bg-red {
  background-color: #ef4136;
  border-color: #ef4136
}

</style>
