<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="">
        <div class="container oversized-container">
          <b-navbar-brand href="/">
            <img src="public/images/logo.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="far fa-bars icon"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="simple-item" to="/">Inicio</b-nav-item>

              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>Catálogo de documentos</span>
                </template>


                <b-dropdown-item :to="'/contratos/'+$root._converToURL(c.title,c.id)" v-for="(c,indx) in contracts" :key="indx">{{c.title}}</b-dropdown-item>

                <!-- <b-dropdown-item to="/contratos/documento-de-consentimiento-para-relacion-casual">Documento de consentimiento <span class="d-block d-sm-inline-block d-lg-block">para relación casual</span></b-dropdown-item>
                <b-dropdown-item to="/contratos/contratos-de-servicios-temporal">Contratos de servicios temporal</b-dropdown-item>
                <b-dropdown-item to="/contratos/contratos-de-servicios-profesionales">Contratos de servicios <span class="d-lg-block">profesionales</span></b-dropdown-item> -->
                <!-- <b-dropdown-item to="/contratos">Todos los contratos</b-dropdown-item> -->
              </b-nav-item-dropdown>

              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>Nosotros</span>
                </template>
                <b-dropdown-item to="/como-te-protegemos">¿Cómo te protegemos?</b-dropdown-item>
                <b-dropdown-item to="/preguntas-frecuentes">Preguntas frecuentes</b-dropdown-item>
                <b-dropdown-item to="/empresa">Sobre la empresa</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown class="simple-item" right v-if="$root.logged">
                <template #button-content>
                  <span><i class="fas fa-user-cog"></i> Mi Cuenta</span>
                </template>
                <b-dropdown-item to="/usuario">Mi Información básica</b-dropdown-item>
                <b-dropdown-item to="/usuario/documentacion">Mi Documentación</b-dropdown-item>
                <!-- <b-dropdown-item to="/usuario/fotografia">Mi foto de perfil</b-dropdown-item> -->
                <b-dropdown-item to="/usuario/firma">Mi firma</b-dropdown-item>
                <b-dropdown-item to="/usuario/contrasena">Mi contraseña</b-dropdown-item>
                <b-dropdown-item to="/usuario/contratos">Contratos</b-dropdown-item>
                <b-dropdown-item to="/usuario/contactos">Directorio de contactos</b-dropdown-item>
                <b-dropdown-item to="/usuario/comprar-creditos">Comprar créditos</b-dropdown-item>
                <li class="nav-item" style="text-align:center;cursor: pointer;" @click="logout()">
                  <a class="nav-link">Cerrar sesión</a>
                </li>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown class="simple-item" right v-else>
                <template #button-content>
                  <span><i class="fas fa-user"></i> Mi Cuenta</span>
                </template>
                <b-dropdown-item to="/login">Iniciar sesión</b-dropdown-item>
                <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item btn-item" to="/contacto">Contáctanos</b-nav-item>

              <li class="nav-item nav-icon-circle">
                <div class="nav-link">
                  <!-- <router-link class="btn-network t-250 btn-con" to="/contacto"><i class="fal fa-envelope"></i></router-link> -->
                  <a class="t-250 btn-network btn-fac"><i class="fab fa-instagram"></i></a>
                  <a class="t-250 btn-network btn-twi"><i class="fab fa-tiktok"></i></a>
                </div>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <!-- <div class="header-search">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-4 align-self-center col-left">
            <h6 v-if="$root.logged"><i class="fas fa-user-circle"></i> Hola <strong>{{$root.user.name}}</strong></h6>
            <span v-if="$root.logged" class="sep">|</span>
            <h6 v-if="$root.logged">Documentos disponibles: <strong>{{$root.user.credits}}</strong></h6>
          </div>

          <div class="col-lg-8 col-right">
            <div class="content">
              <b-form inline @submit="onSubmit">
                <b-form-input
                  v-model="formSearch.keywords"
                  type="text"
                  placeholder="BUSCA EL CONTRATO QUE NECESITES"
                  required
                ></b-form-input>
                <b-button type="submit" class="t-250 btn-search">BUSCAR</b-button>
                <router-link class="t-250 btn-asesoria" to="/contacto">ASESORÍA</router-link>
              </b-form>

            </div>
          </div>
        </div>
      </div>
    </div> -->

  </header>
</template>

<script>
export default {
  data(){
    return{
      formSearch: {
        keywords: null
      },
      contracts:[]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.$router.push("/contratos");
    },
    getRows(){
        axios.get(tools.url("/api/contracts")).then((response)=>{
            this.contracts = response.data;
        }).catch(()=>{});
    },
    logout(){
        axios.post(tools.url("/api/logout")).then((response)=>{
            this.$root.user = {};
            this.$root.logged = false;
            this.$router.push('/login');
        }).catch(()=>{});
    },
  },
  mounted(){
       this.getRows();
  }
}
</script>
